import type { App, Plugin } from "vue"

interface FormatterOptions {

}

class Formatter {
    constructor() {

    }

    number(value: number | null | undefined, zero?: string): string {
        if (value == null || value == undefined || value == 0) {
            return (zero || '/')
        }

        return new Intl.NumberFormat('nl-NL').format(Math.round(value))
    }
}


declare module '@vue/runtime-core' {
    export interface ComponentCustomProperties {
        $format: Formatter
    }
}

export const format: Plugin = {
    install: (app: App, options: FormatterOptions) => {
        app.config.globalProperties.$format = new Formatter()
    }
}