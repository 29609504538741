// import './assets/main.css'
import './assets/oplanet.css'

import App from './App.vue'
import router from './router'
import { api } from './api'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { i18n } from './localization'
import { format } from './plugins/format'

import directives from './directives';
import TooltipVue from './components/common/Tooltip.vue'

// Global Components


const app = createApp(App)
app.use(createPinia())
app.use(router)
app.use(i18n)
app.use(format)
app.use(api, { base_url: "https://trashsim.oplanet.eu/api" })

app.component('Tooltip', TooltipVue)

directives(app);

app.mount('#app')
