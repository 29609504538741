import type { EntityCount } from "@/lib/newsimulator/party"
import type { Fleet } from "@/lib/types"

export function getEntities(fleet: Fleet): EntityCount {
    let result: EntityCount = {}

    for (let type in fleet.ships) {
        if (fleet.ships[type] != null) {
            result[type] = fleet.ships[type] || 0
        }
    }

    if (!fleet.isDefender) {
        return result
    }


    for (let type in fleet.defences) {
        if (fleet.defences[type] != null) {
            result[type] = fleet.defences[type] || 0
        }
    }

    return result
}