<script setup lang="ts">
import { defineComponent } from 'vue';

</script>

<template>
    <div id="cr-howto">
        <h3 class="subtitle">{{ $t('home.howto') }}</h3>
        <ol class="clearfix">
    		<li data-step="step-1">
                <div class="cr-howto-image cr-howto-image-step1"></div>
    			<div class="cr-howto-pin" id="step-1">1</div>
    			<div class="cr-howto-content">
					<p v-html="$t('home.step1.paragraph1')"></p>
					<p v-html="$t('home.step1.paragraph2')"></p>
					<p v-html="$t('home.step1.paragraph3')"></p>
    			</div>
    		</li>
    		<li data-step="step-2">
                <div class="cr-howto-image cr-howto-image-step2"></div>
    			<div class="cr-howto-pin" id="step-2">2</div>
    			<div class="cr-howto-content">
					<p v-html="$t('home.step2.paragraph1')"></p>
					<p v-html="$t('home.step2.paragraph2')"></p>
					<p v-html="$t('home.step2.paragraph3')"></p>
    			</div>
    		</li>
    		<li data-step="step-3">
                <div class="cr-howto-image cr-howto-image-step3"></div>
    			<div class="cr-howto-pin" id="step-3">3</div>
    			<div class="cr-howto-content">
					<p v-html="$t('home.step3.paragraph1')"></p>
					<p v-html="$t('home.step3.paragraph2')"></p>
					<p v-html="$t('home.step3.paragraph3')"></p>
    			</div>
    		</li>
        </ol>
    </div>
</template>

<script lang="ts">
export default defineComponent({
    
})
</script>