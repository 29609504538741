import type { CalculationResults, Resources as ResultResources } from "./result";
import type { Settings } from "../settings";
import type { Outcome, PartyEntityCount, SimulationResult, SimulationResults } from "../simulator/worker";
import CalculationResult from "./result";
import type { Parties, PartyKey } from "../types";
import { type EntityInfo } from "../entityInfo";

type reduceAll = (prev: CalculationResult, current: CalculationResult, currentIndex: number, array: CalculationResult[]) => CalculationResult
type reduceShort = (prev: CalculationResult, current: CalculationResult) => CalculationResult

export type caseFilters = {
    [key: string] : (reduceShort | reduceAll)
}

export enum ResultCases {
    Average = "average",
    AttackersBest = "attackersBest",
    DefendersBest = "defendersBest",
    AttackersWorst = "attackersWorst",
    DefendersWorst = "defendersWorst",
    RecyclersHighest = "recyclersHighest"
}

class Calculator {
    static calculate(
        simulations: SimulationResults,
        entities: EntityInfo,
        settings: Settings,
        engineer: boolean,
        flightData: any,
        parties: Parties
    ): CalculationResults {
        let results = [];
        let outcome: Outcome = {
            attackers: 0,
            defenders: 0,
            draw: 0
        }

        let resources = parties.defenders.fleets[0].resources
        let resultResources: ResultResources = {
            metal: resources.metal || 0,
            crystal: resources.crystal || 0,
            deuterium: resources.deuterium || 0,
            food: resources.food || 0
        }

        for (let i=0; i < simulations.length; i++) {
            let canPlunder: boolean = simulations[i].outcome.attackers > 0;
            results.push(new CalculationResult(
                simulations[i],
                entities,
                settings,
                resultResources,
                canPlunder,
                engineer,
                parties                
            ))

            let key: keyof Outcome
            for (key in simulations[i].outcome) {
                outcome[key] += simulations[i].outcome[key]
            }
        }

        let cr: CalculationResults = <CalculationResults>{
            cases: {},
            outcome: outcome
        };
        let desiredCases = Calculator.getDesiredResultCases();
        for (let desiredCase in desiredCases) {
            cr.cases[desiredCase] = results.reduce(desiredCases[desiredCase])
        }

        cr.cases[ResultCases.Average] = new CalculationResult(
            Calculator.getAverageSimulation(simulations),
            entities,
            settings,
            resultResources,
            outcome.attackers > 0,
            engineer,
            parties
        )

        console.debug('calculation results', cr)

        return cr
    }

    static getAverageSimulation(simulations: SimulationResults): SimulationResult {
        let result = <SimulationResult>{
            lost: <PartyEntityCount>{},
            remaining: <PartyEntityCount>{},
            outcome: <Outcome>{ attackers: 0, defenders: 0, draw: 0 },
            rounds: 0
        }

        let party: PartyKey;
        for (const res of simulations) {
            for (party in res.lost) {
                if (!(party in result.lost)) {
                    result.lost[party] = []
                }
                for (const index in res.lost[party]) {
                    if (!(index in result.lost[party])) {
                        result.lost[party][index] = {}
                    }
                    result.lost[party][index] = sumEntities(
                        res.lost[party][index],
                        result.lost[party][index] || {}
                    )
                }
            }

            for (party in res.remaining) {
                if (!(party in result.remaining)) {
                    result.remaining[party] = []
                }
                for (const index in res.remaining[party]) {
                    if (!(index in result.remaining[party])) {
                        result.remaining[party][index] = {}
                    }
                    result.remaining[party][index] = sumEntities(
                        res.remaining[party][index],
                        result.remaining[party][index] || {}
                    )
                }
            }

            result.outcome.attackers += res.outcome.attackers
            result.outcome.defenders += res.outcome.defenders
            result.outcome.draw += res.outcome.draw
            result.rounds += res.rounds
        }

        for (party in result.lost) {
            for (const index in result.lost[party]) {
                result.lost[party][index] = divideEntities(result.lost[party][index], simulations.length)
            }
        }

        for (party in result.remaining) {
            for (const index in result.remaining[party]) {
                result.remaining[party][index] = divideEntities(result.remaining[party][index], simulations.length)
            }
        }

        result.rounds = Math.round(result.rounds / simulations.length)

        return result
    }

    static getDesiredResultCases(): caseFilters {
        return {
            attackersBest: (prev: CalculationResult, current: CalculationResult) => {
                return (prev.profits.attackers.total > current.profits.attackers.total) ? prev : current;
            },
            defendersBest: (prev: CalculationResult, current: CalculationResult) => {
                return (prev.profits.defenders.total > current.profits.defenders.total) ? prev : current;
            },
            attackersWorst: (prev: CalculationResult, current: CalculationResult) => {
                return (prev.profits.attackers.total < current.profits.attackers.total) ? prev : current;
            },
            defendersWorst: (prev: CalculationResult, current: CalculationResult) => {
                return (prev.profits.defenders.total < current.profits.defenders.total) ? prev : current;
            },
            recyclersHighest: (prev: CalculationResult, current: CalculationResult) => {
                return (prev.debris.remaining.total > current.debris.remaining.total) ? prev : current;
            }
        }
    }
}

function sumEntities(a: { [key: string]: number }, b: { [key: string]: number }): { [key: string]: number } {
    let result: { [key: string]: number } = {}
    for (const type in a) {
        result[type] = a[type] + (b[type] || 0)
    }

    return result
}

function divideEntities(a: { [key: string]: number }, b: number): { [key: string]: number } {
    let result: { [key: string]: number } = {}
    for (const type in a) {
        result[type] = Math.round(a[type] / b)
    }

    return result
}

export default Calculator