import type { EntityInfo } from "@/lib/entityInfo";
import type { PartyData } from "@/lib/newsimulator/simulator";
import type { Settings } from "@/lib/settings";
import type { Party } from "@/lib/types";
import { getEntities } from "./fleet";
import { CreateEntitySimpleStats } from "./entity";


function CreateSimulationParty(party: Party, entityInfo: EntityInfo, settings: Settings): PartyData {
    let data: PartyData = { entities: [], stats: [] }

    party.fleets.forEach((fleet, index) => {
        data.entities[index] = getEntities(fleet)
        data.stats[index] = {}

        for (const entityId in entityInfo) {
            const baseStatsBooster = (settings.lifeformsEnabled ? (fleet.lifeformsBonuses?.BaseStatsBooster || {})[entityId] || null : null )

            data.stats[index][entityId] = CreateEntitySimpleStats(entityId, entityInfo[entityId], fleet.techs, baseStatsBooster, settings)
        }
    })

    return data
}

function CreateCalculationParty(party: Party, entityInfo: EntityInfo, settings: Settings): PartyData {
    return <PartyData>{}
}

export {
    CreateSimulationParty,
    CreateCalculationParty
}