<script setup lang="ts">
import ResultTablePL from './ResultTablePL.vue';
import ResultTablePlanet from './ResultTablePlanet.vue';

import type { Resources } from '@/lib/calculator/result';
import type CalculationResult from '@/lib/calculator/result';
import { PartyMembers } from '@/lib/types';
import type { Wave } from '@/lib/trashsim';
import { defineComponent, toRaw } from 'vue';

export interface Props {
    modelValue: Wave[]
}
const props = defineProps<Props>()

// export interface Emits {
//     (e: 'clear', value: Event): void
// }
// const emits = defineEmits<Emits>()
</script>

<template>
    <div id="results-total" v-if="waves.length > 1">
        <div id="results-total-title">
            <h3>{{ $t('home.resultstotal.title') }}</h3>
        </div>
        <div class="results-tables">
            <ResultTablePL
                v-for="party of PartyMembers"
                :party="party"
                :profits="total?.profits[party]"
                :loses="total?.loses[party]"
            />

            <ResultTablePlanet :debris="total?.debris" />

            <div class="result-table-plunder result-table">
                <table class="resource-table">
                    <thead><tr><th colspan="2">Plunder Captured</th></tr></thead>
                    <tbody>
                    <tr v-for="resource in ['metal', 'crystal', 'deuterium', 'food'].map(v => v as keyof Resources)">
                        <td><div class="resource" :class="'resource-'+resource"></div></td>
                        <td>{{ formatNumber(total?.plunder[resource] || 0) }}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="total">
                        <td><div class="fixed resource-total">=</div></td>
                        <td>{{ formatNumber((total?.plunder.metal || 0) + (total?.plunder.crystal || 0) + (total?.plunder.deuterium || 0) + (total?.plunder.food || 0)) }}</td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default defineComponent({
    computed: {
        waves() {
            return this.modelValue
        },
        total(): (CalculationResult | null) {
            let result = <CalculationResult>{
                loses: {
                    attackers: { metal: 0, crystal: 0, deuterium: 0, total: 0 },
                    defenders: { metal: 0, crystal: 0, deuterium: 0, total: 0 }
                },
                profits: {
                    attackers: { metal: 0, crystal: 0, deuterium: 0, total: 0 },
                    defenders: { metal: 0, crystal: 0, deuterium: 0, total: 0 }
                },
                debris: {
                    remaining: { metal: 0, crystal: 0, deuterium: 0, total: 0 },
                    recyclers: 0
                },
                plunder: { metal: 0, crystal: 0, deuterium: 0, food: 0 }
            };

            for (const wave of this.waves) {
                if (wave.result == null) {
                    return result
                }

                console.log("wave result", toRaw(wave.result))

                for (const party of PartyMembers) {
                    result.loses[party].metal += wave.result.loses[party].metal
                    result.loses[party].crystal += wave.result.loses[party].crystal
                    result.loses[party].deuterium += wave.result.loses[party].deuterium
                    result.loses[party].total += wave.result.loses[party].total

                    result.profits[party].metal += wave.result.profits[party].metal
                    result.profits[party].crystal += wave.result.profits[party].crystal
                    result.profits[party].deuterium += wave.result.profits[party].deuterium
                    result.profits[party].total += wave.result.profits[party].total
                }

                result.debris.remaining.metal += wave.result.debris.remaining.metal
                result.debris.remaining.crystal += wave.result.debris.remaining.crystal
                result.debris.remaining.deuterium += wave.result.debris.remaining.deuterium
                result.debris.remaining.total += wave.result.debris.remaining.total
                result.debris.recyclers += wave.result.debris.recyclers

                result.plunder.metal += wave.result.plunder.metal
                result.plunder.crystal += wave.result.plunder.crystal
                result.plunder.deuterium += wave.result.plunder.deuterium
                result.plunder.food += wave.result.plunder.food
            }

            return result
        }
    },
    methods: {
        clearWaves(event: Event) {
            this.$emit('clear', event)
        },
        formatNumber(value: number, zero?: string): string {
            return (value == 0) ? (zero || '/') : new Intl.NumberFormat('nl-NL').format(Math.round(value))
        },
    }
})
</script>