<script setup lang="ts">
import { defineComponent, ref, toRaw } from 'vue';
import { useKeypress } from 'vue3-keypress';

// components
import Title from '@/components/Title.vue';
import PartyComponent from '@/components/PartyComponent.vue';
import TopControls from '@/components/TopControls.vue';
import BottomControls from '@/components/BottomControls.vue';
import TotalResultsComponent from '@/components/TotalResultsComponent.vue';
import HowToComponent from '@/components/HowToComponent.vue';
import DefenderComponent from '@/components/Defender.vue';
import SettingsComponent from '@/components/SettingsComponent.vue';
import ResultComponent from '@/components/ResultComponent.vue';
import ProgressComponent from '@/components/ProgressComponent.vue';
import OptionsComponent from '@/components/OptionsComponent.vue';

// libraries
import { PartyMembers } from '@/lib/types';
import { Simulator } from '@/lib/trashsim/simulator';
import type { ProgressEvent } from '@/lib/trashsim/workers';

import Calculator from '@/lib/calculator/calculator';
import { Settings, SettingsFromServerData } from '@/lib/settings';
import { NewFleetFromAPI, NewParties } from '@/lib/types';
import type { ServerData } from '@/api/types';
import { NewWaveFromWave, type Options, type Wave } from '@/lib/trashsim';
import { GetEntitiesFromVersion } from '@/lib/entityInfo';
import { CreateSimulationParty } from '@/lib/trashsim/loaders/party';
import { LoadPrefill } from '@/lib/trashsim/loaders';

export declare interface Data {
    settings: Settings
    waves: Array<Wave>
    selectedWave: number
    simulator: Simulator
    active: boolean
    completedRounds: number
    options: Options
    debugResult: string
}

const debug = ref(false);
useKeypress({
    keyEvent: "keydown",
    keyBinds: [
        {
            keyCode: 68,
            modifiers: ["ctrlKey", "altKey"],
            success: function() {
                debug.value = !debug.value
            },
            preventDefault: true
        }
    ]
})

</script>

<template>
    <Title />
    <div id="page-home" class="page-element">
        <form id="simulate-form" @submit="simulate">
            <div id="parties" class="clearfix">
                <div class="party-column" v-for="(_, title) in activeWave.parties">
                    <PartyComponent
                      v-model="activeWave.parties[title]"
                      :defender="activeWave.parties.defenders.fleets[0]"
                      :settings="settings"
                      :result="activeWave.result"
                      :title="title"
                      @submit="simulate"
                      @loadSettings="loadSettings"
                />
                </div>
                <div id="planet-settings-column" class="party-column clearfix">
                    <!-- FIXME: need bindings -->
                    <TopControls :wave="activeWave" :settings="settings" @simulate="simulate"/>
                    <!-- may need to bind all the parties for the IPM -->
                    <DefenderComponent
                        v-model="activeWave.parties['defenders'].fleets[0]"
                        :result="activeWave.result"
                        :settings="settings"
                        @submit="simulate"
                    />
                    <SettingsComponent v-model="settings" />
                </div>
            </div>
            <BottomControls
                v-model="selectedWave"
                :waves="waves"
                @submit="simulate"
                @next="newWave"
                @clear="clearWaves"
            />
            <ResultComponent
                v-model="activeWave.result"
                :results="activeWave.results"
                :attacker="activeWave.parties['attackers'].fleets[0]"
                :defender="activeWave.parties['defenders'].fleets[0]"
                :settings="settings"
            />
            <TotalResultsComponent v-model="waves" />
        </form>
        <ProgressComponent 
            :active="active"
            :completedRounds="completedRounds"
            :totalRounds="settings.simulations"
            @cancel="cancelSimulation"
        />
        <HowToComponent />
        <OptionsComponent v-model="options" />
    </div>

    <section id="debug" v-show="debug">
        <div>
            <button @click="dumpEntities">Dump entities</button>
            <button @click="dumpParties">Dump waves</button>
            <button @click="dumpSettings">Dump Settings</button>
            <button @click="dumpDebugPack">Dump debug pack</button>
            <button @click="loadDebugPack">Load debug pack</button>
            <button @click="dumpPrefill">Dump Prefill</button>
        </div>
        <textarea>{{ debugResult }}</textarea>
    </section>
</template>

<style scoped>
#debug {
    position: absolute;
    top: 0;
    left: 0;
    width: 90vw;
    height: 85vh;
    max-height: 90vh;
    margin: 10vh 5vw 5vh 5vw;
    background-color: aquamarine;
    z-index: 9999;
    background: rgba(30, 43, 57, 0.9);
}

#debug div {
    height: 5%;
    max-height: 5%;
}

#debug textarea {
    display: block;
    color: white;
    font-family: 'Courier New', Courier, monospace;
    margin: 0;
    padding: 0;
    border: 0;
    height: 95%;
    max-height: 95%;
    width: 100%;
    max-width: 100%;
    background: none;
}

/* #debug pre span {
    color: red;
} */
</style>

<script lang="ts">
export default defineComponent({
    data(): Data {
        return {
            settings: new Settings(),
            selectedWave: 0,
            waves: [{ parties: NewParties(), results: null, result: null }],
            simulator: new Simulator(),
            active: false,
            completedRounds: 0,
            options: <Options>{ workerCount: null },
            debugResult: "",
        };
    },
    computed: {
        activeWave: {
            get(): Wave {
                return this.waves[this.selectedWave];
            },
            set(value: Wave) {
                this.waves[this.selectedWave] = value;
            }
        }
    },
    mounted() {
        this.simulator.registerCallback((event: ProgressEvent) => { this.completedRounds += 1; });

        if (this.$route.hash.startsWith("#prefill=")) {
            let prefill = LoadPrefill(this.$route.hash);
            this.waves = prefill.waves;

            if (prefill.settings) {
                this.settings = prefill.settings
            }

            this.waves.forEach(wave => {
                for (const party of PartyMembers) {
                    wave.parties[party].fleets.forEach((fleet, index) => {
                        console.debug(`${party}[${index}]`, fleet)
                        if (fleet.API == null) {
                            return
                        }

                        this.$api.spy(fleet.API).then(report => {
                            let newFleet = NewFleetFromAPI(report, party == "defenders" && index == 0)
                            newFleet.API = fleet.API
                            wave.parties[party].fleets[index] = newFleet

                            if (report.server) {
                                this.loadSettings(report.server, report.loot_percentage)
                            }
                        })
                    })
                }
            })
        }
        
        let api = this.$route.query["SR_KEY"]?.toString();
        if (api != undefined) {
            this.$api.spy(api).then(report => {
                console.debug(report)
                this.activeWave.parties.defenders.fleets[0] = NewFleetFromAPI(report, true);
                this.activeWave.parties.defenders.fleets[0].API = api;
                if (report.server != null) {
                    this.loadSettings(report.server, report.loot_percentage);
                }
            }).catch(err => {
                console.error("failed to load key from API", err);
            });
        }
    },
    methods: {
        async simulate() {
            console.debug("simulate triggered", this.settings.simulations);
            let entities = GetEntitiesFromVersion(this.settings.version);
            this.completedRounds = 0;
            this.active = true;
            this.simulator.simulate(toRaw(this.activeWave.parties), entities, this.settings, this.options).then(data => {
                console.log(data)
                this.activeWave.results = Calculator.calculate(data, entities, this.settings, false, null, this.activeWave.parties);
                this.activeWave.result = this.activeWave.results.cases["average"];
                this.truncateWaves();
            }).catch(err => {
                console.debug("simulation error", err);
                this.active = false;
            }).finally(() => {
                this.active = false;
            });
        },
        cancelSimulation() {
            this.active = false;
            this.simulator.reset();
        },
        loadSettings(serverData: ServerData, lootPercentage: number) {
            let newSettings = SettingsFromServerData(serverData, lootPercentage);
            newSettings.simulations = this.settings.simulations;
            this.settings = newSettings;
        },
        newWave() {
            if (this.activeWave.result != null) {
                let wave = NewWaveFromWave(toRaw(this.activeWave));
                this.waves.push(wave);
                this.selectedWave++;
            }
        },
        truncateWaves() {
            if (this.selectedWave + 1 < this.waves.length) {
                this.waves.splice(this.selectedWave + 1, this.waves.length - this.selectedWave + 1);
            }
        },
        clearWaves() {
            this.selectedWave = 0;
            this.waves = [{ parties: NewParties(), results: null, result: null }];
        },

        // some debug handlers
        dumpEntities() {
            let entities = GetEntitiesFromVersion(this.settings.version);
            let result = CreateSimulationParty(toRaw(this.activeWave.parties).defenders, entities, this.settings);
            this.debugResult = JSON.stringify(result, undefined, 2);

            for (const p in result.entities) {
                console.table(result.entities[p])
                console.table(result.stats[p])
            }
        },
        dumpSettings() {
            this.debugResult = JSON.stringify(this.settings, undefined, 2);
        },
        dumpParties() {
            this.debugResult = JSON.stringify(this.waves, undefined, 2);
        },
        dumpDebugPack() {
            this.debugResult = btoa(JSON.stringify({waves: this.waves, settings: this.settings}));
        },
        loadDebugPack() {
            this.debugResult = "I don't do anything just yet"
        },
        dumpPrefill() {
            try {
                this.debugResult = JSON.stringify(LoadPrefill(this.$route.hash))
            }
            catch(err) {
                console.error(err)
            }
        }
    },
    components: { OptionsComponent }
})
</script>