import { NewFleet, NewParties, PartyMembers, PlayerClassFromNumber } from "@/lib/types"
import type { PartyKey, Techs, Coordinates } from '@/lib/types';
import { GetPrefillTechs, type Prefill } from "./prefill";
import type { Wave } from "../types"
import { Settings } from "@/lib/settings";

interface PrefillV1 {
    0: Array<PrefillV1Fleet>
    1: Array<PrefillV1Fleet>
    settings: PrefillV1Settings
}

interface PrefillV1Settings {
    debris_factor: number
    def_to_tF: boolean
    donut_galaxy: boolean
    donut_system: boolean
    galaxies: number
    global_deuterium_save_factor: number
    rapid_fire: number
    repair_factor: number
    speed_fleet: number
    systems: number
}

export interface PrefillV1Fleet {
    class: number
    planet: {
        galaxy: number
        system: number
        position: number
    }
    resources?: {
        metal: number,
        crystal: number,
        deuterium: number
    }
    research?: { [key: string ]: {level: number} }
    ships?: { [key: string ]: {count: number} }
    defence?: { [key: string ]: {count: number} }
}

const researchMappings = <{[key: string]: keyof Techs}>{
    "109": "armour",
    "110": "shield",
    "111": "weapon",
    "114": "hyperspacetech",
    "115": "combustion",
    "117": "impulse",
    "118": "hyperspace"
}

class PrefillV1Parser {
    raw: object

    constructor(raw: object) {
        this.raw = raw
    }

    load(): Prefill {
        if (!("0" in this.raw) || !("1" in this.raw)) {
            throw new Error('attackers or defenders not set')
        }

        let data = <PrefillV1>this.raw

        let wave = this.loadWave(data);
        let settings = this.loadSettings(data.settings);

        return {
            waves: [wave],
            settings: settings,
        }
    }

    loadWave(prefill: PrefillV1): Wave {
        const parties = <{[key: string]: PrefillV1Fleet[]}>{
            'attackers': prefill[0],
            'defenders': prefill[1]
        }

        let result = NewParties()
        for (const party of PartyMembers) {
            if (parties[party] == undefined) {
                continue
            }

            let fleetIndex = 0;
            for (const current of parties[party]) {
                const isDefender = party == 'defenders' && fleetIndex == 0;
                let fleet = NewFleet(isDefender);

                fleet.class = PlayerClassFromNumber(current.class)
                fleet.coordinates = <Coordinates>{
                    galaxy: current.planet.galaxy,
                    system: current.planet.system,
                    position: current.planet.position,
                }

                if (current.resources != undefined) {
                    fleet.resources = {
                        metal: current.resources.metal,
                        crystal: current.resources.crystal,
                        deuterium: current.resources.deuterium,
                    }
                }

                if (current.ships != undefined) {
                    for (const e in current.ships) {
                        fleet.ships[e] = current.ships[e].count;
                    }
                }

                if (current.defence != undefined) {
                    for (const e in current.defence) {
                        fleet.defences[e] = current.defence[e].count;
                    }
                }

                if (current.research != undefined) {
                    const presearch: {[key: string]: number} = {}
                    for (const key in current.research) {
                        presearch[key] = current.research[key].level
                    }

                    fleet.techs = GetPrefillTechs(presearch)
                }

                result[party].fleets[fleetIndex] = fleet;
            };
            fleetIndex++;
        }

        return {
            parties: result,
            results: null,
            result: null
        }
    }

    loadSettings(prefill?: PrefillV1Settings): Settings | undefined {
        let settings = new Settings()

        if (prefill == undefined) {
            return undefined
        }

        settings.fleetToDebris = prefill.debris_factor * 100;
        settings.fleetWarSpeed = prefill.speed_fleet;
        settings.fleetHoldSpeed = prefill.speed_fleet;
        settings.donutGalaxy = Boolean(prefill.donut_galaxy);
        settings.donutSystem = Boolean(prefill.donut_system);
        settings.numberOfGalaxies = prefill.galaxies;
        settings.deuteriumSaveFactor = prefill.global_deuterium_save_factor;
        settings.defenceRepair = prefill.repair_factor * 100;

        if (prefill.def_to_tF) {
            settings.defenceToDebris = 100 - settings.defenceRepair;
        }

        return settings
    }
}

export {
    PrefillV1Parser
}