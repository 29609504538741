<script setup lang="ts">
import  { defineComponent } from 'vue';

</script>

<template>
    <div id="uv-apps-bar">
        <div id="uv-apps-bar-content">
            <div id="uv-apps-logo">
                <img src="@/assets/moon.png">
                <span id="uv-apps-wide">UniverseView</span>
                <span id="uv-apps-mobile">Uv</span>
                <br>
                Apps
            </div>
            <div id="uv-apps-menu">
                <span>≡</span>
                <menu>
                    <menuitem class="uv-apps-inactive">
                        <a href="https://trashsim.oplanet.eu/" target="_blank">TrashSim</a>
                    </menuitem>
                    <menuitem class="uv-apps-inactive">
                        <a href="https://ogotcha.oplanet.eu/" target="_blank">OGotcha</a>
                    </menuitem>
                </menu>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default defineComponent({})
</script>

<style scoped>
#uv-apps-bar, #uv-apps-logo img {
    position:   absolute;
    height:     60px;
    left:       0;
}

#uv-apps-bar {
    background:#1E2B39;
    color:      #fff;
    top:        0;
    width:      100%;
    box-shadow: 0 2px 20px rgba(0,0,0,.4);
    font-size:  16px;
}

#uv-apps-bar-content {
    max-width:      900px;
    width:          100%;
    margin:         0 auto;
    clear:          both;
    z-index:        1;
    position:       relative;
}

#uv-apps-logo {
    clear:          both;
    padding:        14px 0 14px 70px;
    position:       relative;
    float:          left;
    z-index:        0;
}

#uv-apps-logo img {
    top:            5px;
    width:          60px;
    z-index:        -1;
}

#uv-apps-menu > span, #uv-apps-mobile {
    display:        none;
}

#uv-apps-menu {
    float:          right;
    border-radius:  3px
}

#uv-apps-menu menu {
    margin:0;
    padding:0;
    clear:both;
    list-style:none
}

#uv-apps-menu menu menuitem {
    float:left;
    display:block;
    border-width:0 1px 0 0;
    border-color:rgba(80,103,132,.3);
    border-style:solid
}

#uv-apps-menu menu menuitem:first-child {
    border-width:0 1px
}

#uv-apps-menu menu menuitem.uv-apps-active {
    background-color:rgba(80,103,132,.5)
}

#uv-apps-menu menu menuitem a {
    color:#fff;
    text-decoration:none;
    height:60px;
    line-height:60px;
    padding:0 10px;
    display:block;
    border-bottom:3px solid rgba(80,103,132,.5)
}

#uv-apps-menu menu menuitem a:hover {
    background-color:rgba(0,0,0,.4)!important
}

#uv-apps-menu menu menuitem.uv-apps-active a {
    border-bottom-color:#506784
}

.uv-apps-popup {
    background-color:rgba(0,0,0,.75);
    color:#fff;
    position:fixed;
    z-index:999;
    display:flex;
    align-items:center;
    justify-content:center;
    top:0;
    left:0;
    bottom:0;
    right:0
}

#uv-apps-migration {
    background-color:rgba(0,0,0,.9)
}

.uv-apps-popup-content{
    max-width:600px;
    width:100%;
    margin:0 auto;
    box-sizing:border-box;
    padding:25px;
    position:relative;
    background-color:rgba(30,43,57,.9)
}

.uv-apps-popup-content h2, .uv-apps-popup-content h3 {
    margin:0
}

.uv-apps-popup-content p {
    margin:10px 0 0
}

.uv-apps-popup-content a {
    color:#fff
}

.uv-apps-popup-content a img {
    vertical-align:bottom
}

.uv-apps-popup-header{
    display:flex;
    flex-flow:row wrap;
    align-items:center;
    justify-content:space-between;
    margin-bottom:15px
}

.uv-apps-popup-header *+*{
    margin-left:15px
}

.uv-apps-popup-translate {
    font-size:1.25rem
}

.uv-apps-popup-btn {
    border:3px solid #506784;
    border-radius:3px;
    padding:7px 15px;
    display:inline-block;
    margin-top:10px;
    cursor:pointer;
    transition:.25s
}

.uv-apps-popup-btn:hover {
    background-color:#506784
}

.uv-apps-popup-agree::before {
    content:"";
    display:inline-block;
    border:solid #506784;
    border-width:0 3px 3px 0;
    width:6px;
    height:11px;
    transform:rotateZ(45deg);
    margin:0 15px 0 0;
    transition:.25s
}

.uv-apps-popup-agree:hover::before {
    border-color:#fff
}

.uv-apps-popup-close {
    color:rgba(255,255,255,.25);
    position:absolute;
    top:15px;
    right:15px
}

.uv-apps-popup-actions{
    display:flex;
    flex-flow:row wrap;
    align-items:flex-end;
    justify-content:space-between;
    margin-top:15px
}

.uv-apps-brave-logo {
    display:flex;
    flex-flow:row wrap;
    align-items:flex-start;
    justify-content:space-between
}

.uv-apps-brave-download, .uv-apps-migration-post {
    display:inline-block;
    border-radius:3px;
    padding:11px 19px;
    margin-top:10px
}

.uv-apps-brave-logo svg {
    max-width:100px
}

.uv-apps-brave-download{
    background:#fb542b;
    font-size:1.1rem
}

.uv-apps-migration-post{
    background:#506784;
    text-decoration:none
}

@media (max-width:43.750em) {
    #uv-apps-bar-content {
        width:96%;
        padding:0 2%
    }
    
    #uv-apps-logo {
        padding:16px 0 14px 13px;
        text-align:center;
        text-shadow:0 1px 10px rgba(0,0,0,1);
        font-weight:700
    }
    
    #uv-apps-menu menu, #uv-apps-wide {
        display:none
    }
    
    #uv-apps-mobile {
        display:inline
    }
    
    #uv-apps-menu {
        padding:5px;
        margin-top:7px
    }
    
    #uv-apps-menu:hover {
        background-color:#39495e
    }
    
    #uv-apps-menu > span {
        display:block;
        font-size:3em;
        cursor:pointer;
        text-align:right;
        line-height:36px
    }
    
    #uv-apps-menu:hover menu {
        display:block
    }
    
    #uv-apps-menu menu menuitem{
        display:list-item;
        float:none;
        border:0;
        margin-top:5px;
        text-align:center
    }
    
    #uv-apps-menu menu menuitem, #uv-apps-menu menu menuitem a {
        border-radius:2px
    }
    
    #uv-apps-menu menu menuitem.uv-apps-inactive a {
        background-color:rgba(0,0,0,.2)
    }
    
    #uv-apps-menu menu menuitem a {
        border-bottom:0
    }
}
</style>