<script setup lang="ts">
import { defineComponent } from 'vue';

import type { DebrisResources } from '@/lib/calculator/result';

export interface Props {
    party: string
    profits: DebrisResources | undefined
    loses: DebrisResources | undefined
}

const props = defineProps<Props>()

</script>

<template>
    <div class="result-table">
        <div :id="`result-${party}-profit-content`" class="tooltip-content">{{ $t(`home.result.profit.${party}.tooltip`) }}</div>
        <table class="resource-table">
            <thead>
                <tr><th colspan="4">{{ $t('home.result.'+party) }}</th></tr>
                <tr>
                    <th colspan="2">{{ $t('home.result.losses') }}</th>
                    <th colspan="2" id="result-attackers-profit" class="tooltip-hover">{{ $t('home.result.profit.title') }} *</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="resource of ['metal', 'crystal', 'deuterium'].map(v => v as keyof DebrisResources)">
                    <td><div class="resource" :class="'resource-'+resource"></div></td>
                    <td>{{ $format.number(loses?.[resource]) }}</td>
                    <td><div class="resource" :class="'resource-'+resource"></div></td>
                    <td 
                        v-bind:class="{
                            profit: (profits?.[resource] || 0) > 0,
                            loss: (profits?.[resource] || 0) < 0
                        }"
                    >
                        {{ $format.number(profits?.[resource]) }}
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr class="total">
                    <td><div class="fixed resource-total">=</div></td>
                    <td class="ng-binding">{{ $format.number(loses?.total) }}</td>
                    <td><div class="fixed resource-total">=</div></td>
                    <td
                        v-bind:class="{
                            profit: (profits?.total || 0) > 0,
                            loss: (profits?.total || 0) < 0
                        }"
                    >
                    {{ $format.number(profits?.total) }}
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script lang="ts">
export default defineComponent({})
</script>