<script setup lang="ts">
import type Simulator from '@/lib/simulator/simulator';
import { defineComponent, reactive, ref, type Ref } from 'vue';

export interface Props {
    active: boolean
	completedRounds: number
	totalRounds: number
}

const props = defineProps<Props>()
</script>

<template>
<div id="simulation-progress" class="simulation-background" v-bind:class="{working: active}">
		<div id="progress-content">
			<div id="progress-title" class="clearfix">{{ $t('home.simulation.title') }}</div>
			<div id="simulations-progress"><span>{{ completedRounds }}</span>/<span class="ng-binding">{{ totalRounds }}</span></div>
			<div class="cssload-cube">
				<div class="cssload-plane-1">
					<div class="cssload-top-left"></div>
					<div class="cssload-top-middle"></div>
					<div class="cssload-top-right"></div>
					<div class="cssload-middle-left"></div>
					<div class="cssload-middle-middle"></div>
					<div class="cssload-middle-right"></div>
					<div class="cssload-bottom-left"></div>
					<div class="cssload-bottom-middle"></div>
					<div class="cssload-bottom-right"></div>
				</div>
				 <div class="cssload-plane-2">
					<div class="cssload-top-left"></div>
					<div class="cssload-top-middle"></div>
					<div class="cssload-top-right"></div>
					<div class="cssload-middle-left"></div>
					<div class="cssload-middle-middle"></div>
					<div class="cssload-middle-right"></div>
					<div class="cssload-bottom-left"></div>
					<div class="cssload-bottom-middle"></div>
					<div class="cssload-bottom-right"></div>
				</div>
				 <div class="cssload-plane-3">
					<div class="cssload-top-left"></div>
					<div class="cssload-top-middle"></div>
					<div class="cssload-top-right"></div>
					<div class="cssload-middle-left"></div>
					<div class="cssload-middle-middle"></div>
					<div class="cssload-middle-right"></div>
					<div class="cssload-bottom-left"></div>
					<div class="cssload-bottom-middle"></div>
					<div class="cssload-bottom-right"></div>
				</div>
			</div>
			<div>
				<div id="progress-bar"><div id="progress-status" style="width: %"></div></div>
			</div>
			<div class="clearfix">
				<div id="progress-rounds-wrapper">{{ $t('home.simulation.round') }}: <span class="ng-binding"></span></div>
				<div id="cancel-simulation" @click="cancelSimulation()">{{ $t('home.cancel') }}</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
export default defineComponent({
    methods: {
        cancelSimulation() {
            if (this.active) {
                console.debug('resetting simulation')
                this.$emit('cancel')
            }
        }
    }
})
</script>