<script setup lang="ts">
import type { Options } from '@/lib/trashsim';
import { defineComponent } from 'vue';

interface Props {
    modelValue: Options
}
interface Emits {
    ( e: 'update:modelValue', value: Options): void
}

const props = defineProps<Props>()
const emits = defineEmits<Emits>()

</script>

<template>
    <div id="options">
        <h3 class="subtitle">{{ $t('home.options.title') }}</h3>
        <div id="options-container">
            <ul>
                <li class="enabled">
                    <label>
                        <input type="checkbox" disabled checked />
                        <span>{{ $t('home.options.feature.javascriptmw') }}</span>
                    </label>
                    <div class="extra">
                        <label>
                            <span>{{ $t('home.options.workercount') }}</span>
                            <input type="number" v-model="options.workerCount">
                        </label>
                    </div>
                </li>
                <li>
                    <label>
                        <span>{{ $t('home.options.entityinfo') }}</span>
                        <br>
                        <textarea v-model="options.entities" disabled></textarea>
                    </label>
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
export default defineComponent({
    computed: {
        options: {
            get(): Options {
                return this.modelValue
            },
            set(value: Options) {
                this.$emit('update:modelValue', value)
            }
        }
    }
})
</script>