<script setup lang="ts">
import type { Fleet } from '@/lib/types';
import { defineComponent } from 'vue';

export interface Props {
    modelValue: Fleet
}

const props = defineProps<Props>()

</script>

<template>
    <div class="player-lifeform-techs">
        <h4>{{ $t('home.player.lfresearch.title') }}</h4>
        <ul class="player-lifeform-techs-row">
            <li v-for="id in activeTechnologies" >
                <span 
                    :class="'lifeform-icon lifeform-tech'+id"
                    v-tooltip="$t('home.player.lfresearch.'+id)+': '+((fleet.lfresearch||{})[id] || 0)"
                ></span>
            </li>
        </ul>
    </div>
</template>

<style scoped>
ul.player-lifeform-techs-row {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    height: 68px;
    justify-content: space-between;
}

.player-lifeform-techs-row li {
    margin: 1px;
}

.player-lifeform-techs-row span.lifeform-icon {
    font-weight: 300;
    font-size: 16px;
    display: flex;
    cursor: pointer;
    background-image: url('//gf3.geo.gfsrv.net/cdne7/2d828476bacc6b1e6bbfaf2a9fb041.jpg') !important;
    width: 32px;
    height: 32px;
    background-size: 1440px 2368px;
    /* width scaling  (18*32)/(18*80)*3600 */
    /* height scaling (18*32)/(18*80)*5920 */
    margin: 0px;
}

/* human techs */
.lifeform-icon.lifeform-tech11201, .lifeform-icon.lifeform-tech11202, .lifeform-icon.lifeform-tech11203, .lifeform-icon.lifeform-tech11204, .lifeform-icon.lifeform-tech11205, .lifeform-icon.lifeform-tech11206, .lifeform-icon.lifeform-tech11207, .lifeform-icon.lifeform-tech11208, .lifeform-icon.lifeform-tech11209, .lifeform-icon.lifeform-tech11210, .lifeform-icon.lifeform-tech11211, .lifeform-icon.lifeform-tech11212, .lifeform-icon.lifeform-tech11213, .lifeform-icon.lifeform-tech11214, .lifeform-icon.lifeform-tech11215, .lifeform-icon.lifeform-tech11216, .lifeform-icon.lifeform-tech11217, .lifeform-icon.lifeform-tech11218 {
    background-position-y: -496px;
}

/* rock'tal techs */
.lifeform-icon.lifeform-tech12201, .lifeform-icon.lifeform-tech12202, .lifeform-icon.lifeform-tech12203, .lifeform-icon.lifeform-tech12204, .lifeform-icon.lifeform-tech12205, .lifeform-icon.lifeform-tech12206, .lifeform-icon.lifeform-tech12207, .lifeform-icon.lifeform-tech12208, .lifeform-icon.lifeform-tech12209, .lifeform-icon.lifeform-tech12210, .lifeform-icon.lifeform-tech12211, .lifeform-icon.lifeform-tech12212, .lifeform-icon.lifeform-tech12213, .lifeform-icon.lifeform-tech12214, .lifeform-icon.lifeform-tech12215, .lifeform-icon.lifeform-tech12216, .lifeform-icon.lifeform-tech12217, .lifeform-icon.lifeform-tech12218 {
    background-position-y: -1088px;
}

/* mecha's techs */
.lifeform-icon.lifeform-tech13201, .lifeform-icon.lifeform-tech13202, .lifeform-icon.lifeform-tech13203, .lifeform-icon.lifeform-tech13204, .lifeform-icon.lifeform-tech13205, .lifeform-icon.lifeform-tech13206, .lifeform-icon.lifeform-tech13207, .lifeform-icon.lifeform-tech13208, .lifeform-icon.lifeform-tech13209, .lifeform-icon.lifeform-tech13210, .lifeform-icon.lifeform-tech13211, .lifeform-icon.lifeform-tech13212, .lifeform-icon.lifeform-tech13213, .lifeform-icon.lifeform-tech13214, .lifeform-icon.lifeform-tech13215, .lifeform-icon.lifeform-tech13216, .lifeform-icon.lifeform-tech13217, .lifeform-icon.lifeform-tech13218 {
    background-position-y: -1680px;
}

/* kaelesh techs */
.lifeform-icon.lifeform-tech14201, .lifeform-icon.lifeform-tech14202, .lifeform-icon.lifeform-tech14203, .lifeform-icon.lifeform-tech14204, .lifeform-icon.lifeform-tech14205, .lifeform-icon.lifeform-tech14206, .lifeform-icon.lifeform-tech14207, .lifeform-icon.lifeform-tech14208, .lifeform-icon.lifeform-tech14209, .lifeform-icon.lifeform-tech14210, .lifeform-icon.lifeform-tech14211, .lifeform-icon.lifeform-tech14212, .lifeform-icon.lifeform-tech14213, .lifeform-icon.lifeform-tech14214, .lifeform-icon.lifeform-tech14215, .lifeform-icon.lifeform-tech14216, .lifeform-icon.lifeform-tech14217, .lifeform-icon.lifeform-tech14218 {
    background-position-y: -2272px;
}

.lifeform-icon.lifeform-tech11201.disabled, .lifeform-icon.lifeform-tech11202.disabled, .lifeform-icon.lifeform-tech11203.disabled, .lifeform-icon.lifeform-tech11204.disabled, .lifeform-icon.lifeform-tech11205.disabled, .lifeform-icon.lifeform-tech11206.disabled, .lifeform-icon.lifeform-tech11207.disabled, .lifeform-icon.lifeform-tech11208.disabled, .lifeform-icon.lifeform-tech11209.disabled, .lifeform-icon.lifeform-tech11210.disabled, .lifeform-icon.lifeform-tech11211.disabled, .lifeform-icon.lifeform-tech11212.disabled, .lifeform-icon.lifeform-tech11213.disabled, .lifeform-icon.lifeform-tech11214.disabled, .lifeform-icon.lifeform-tech11215.disabled, .lifeform-icon.lifeform-tech11216.disabled, .lifeform-icon.lifeform-tech11217.disabled, .lifeform-icon.lifeform-tech11218 {
    background-position-y: -560px;
}

/* rock'tal techs */
.lifeform-icon.lifeform-tech12201.disabled, .lifeform-icon.lifeform-tech12202.disabled, .lifeform-icon.lifeform-tech12203.disabled, .lifeform-icon.lifeform-tech12204.disabled, .lifeform-icon.lifeform-tech12205.disabled, .lifeform-icon.lifeform-tech12206.disabled, .lifeform-icon.lifeform-tech12207.disabled, .lifeform-icon.lifeform-tech12208.disabled, .lifeform-icon.lifeform-tech12209.disabled, .lifeform-icon.lifeform-tech12210.disabled, .lifeform-icon.lifeform-tech12211.disabled, .lifeform-icon.lifeform-tech12212.disabled, .lifeform-icon.lifeform-tech12213.disabled, .lifeform-icon.lifeform-tech12214.disabled, .lifeform-icon.lifeform-tech12215.disabled, .lifeform-icon.lifeform-tech12216.disabled, .lifeform-icon.lifeform-tech12217.disabled, .lifeform-icon.lifeform-tech12218 {
    background-position-y: -1152px;
}

/* mecha's techs */
.lifeform-icon.lifeform-tech13201.disabled, .lifeform-icon.lifeform-tech13202.disabled, .lifeform-icon.lifeform-tech13203.disabled, .lifeform-icon.lifeform-tech13204.disabled, .lifeform-icon.lifeform-tech13205.disabled, .lifeform-icon.lifeform-tech13206.disabled, .lifeform-icon.lifeform-tech13207.disabled, .lifeform-icon.lifeform-tech13208.disabled, .lifeform-icon.lifeform-tech13209.disabled, .lifeform-icon.lifeform-tech13210.disabled, .lifeform-icon.lifeform-tech13211.disabled, .lifeform-icon.lifeform-tech13212.disabled, .lifeform-icon.lifeform-tech13213.disabled, .lifeform-icon.lifeform-tech13214.disabled, .lifeform-icon.lifeform-tech13215.disabled, .lifeform-icon.lifeform-tech13216.disabled, .lifeform-icon.lifeform-tech13217.disabled, .lifeform-icon.lifeform-tech13218 {
    background-position-y: -1744px;
}

/* kaelesh techs */
.lifeform-icon.lifeform-tech14201.disabled, .lifeform-icon.lifeform-tech14202.disabled, .lifeform-icon.lifeform-tech14203.disabled, .lifeform-icon.lifeform-tech14204.disabled, .lifeform-icon.lifeform-tech14205.disabled, .lifeform-icon.lifeform-tech14206.disabled, .lifeform-icon.lifeform-tech14207.disabled, .lifeform-icon.lifeform-tech14208.disabled, .lifeform-icon.lifeform-tech14209.disabled, .lifeform-icon.lifeform-tech14210.disabled, .lifeform-icon.lifeform-tech14211.disabled, .lifeform-icon.lifeform-tech14212.disabled, .lifeform-icon.lifeform-tech14213.disabled, .lifeform-icon.lifeform-tech14214.disabled, .lifeform-icon.lifeform-tech14215.disabled, .lifeform-icon.lifeform-tech14216.disabled, .lifeform-icon.lifeform-tech14217.disabled, .lifeform-icon.lifeform-tech14218 {
    background-position-y: -2336px;
}

/* tier 1 */
.lifeform-icon.lifeform-tech11202, .lifeform-icon.lifeform-tech12202, .lifeform-icon.lifeform-tech13202, .lifeform-icon.lifeform-tech14202 {
    background-position-x: -32px;
}
.lifeform-icon.lifeform-tech11203, .lifeform-icon.lifeform-tech12203, .lifeform-icon.lifeform-tech13203, .lifeform-icon.lifeform-tech14203 {
    background-position-x: -64px;
}
.lifeform-icon.lifeform-tech11204, .lifeform-icon.lifeform-tech12204, .lifeform-icon.lifeform-tech13204, .lifeform-icon.lifeform-tech14204 {
    background-position-x: -96px;
}
.lifeform-icon.lifeform-tech11205, .lifeform-icon.lifeform-tech12205, .lifeform-icon.lifeform-tech13205, .lifeform-icon.lifeform-tech14205 {
    background-position-x: -128px;
}
.lifeform-icon.lifeform-tech11206, .lifeform-icon.lifeform-tech12206, .lifeform-icon.lifeform-tech13206, .lifeform-icon.lifeform-tech14206 {
    background-position-x: -160px;
}
.lifeform-icon.lifeform-tech11207, .lifeform-icon.lifeform-tech12207, .lifeform-icon.lifeform-tech13207, .lifeform-icon.lifeform-tech14207 {
    background-position-x: -192px;
}
.lifeform-icon.lifeform-tech11208, .lifeform-icon.lifeform-tech12208, .lifeform-icon.lifeform-tech13208, .lifeform-icon.lifeform-tech14208 {
    background-position-x: -224px;
}
.lifeform-icon.lifeform-tech11209, .lifeform-icon.lifeform-tech12209, .lifeform-icon.lifeform-tech13209, .lifeform-icon.lifeform-tech14209 {
    background-position-x: -256px;
}
.lifeform-icon.lifeform-tech11210, .lifeform-icon.lifeform-tech12210, .lifeform-icon.lifeform-tech13210, .lifeform-icon.lifeform-tech14210 {
    background-position-x: -288px;
}
.lifeform-icon.lifeform-tech11211, .lifeform-icon.lifeform-tech12211, .lifeform-icon.lifeform-tech13211, .lifeform-icon.lifeform-tech14211 {
    background-position-x: -320px;
}
.lifeform-icon.lifeform-tech11212, .lifeform-icon.lifeform-tech12212, .lifeform-icon.lifeform-tech13212, .lifeform-icon.lifeform-tech14212 {
    background-position-x: -352px;
}
.lifeform-icon.lifeform-tech11213, .lifeform-icon.lifeform-tech12213, .lifeform-icon.lifeform-tech13213, .lifeform-icon.lifeform-tech14213 {
    background-position-x: -384px;
}
.lifeform-icon.lifeform-tech11214, .lifeform-icon.lifeform-tech12214, .lifeform-icon.lifeform-tech13214, .lifeform-icon.lifeform-tech14214 {
    background-position-x: -416px;
}
.lifeform-icon.lifeform-tech11215, .lifeform-icon.lifeform-tech12215, .lifeform-icon.lifeform-tech13215, .lifeform-icon.lifeform-tech14215 {
    background-position-x: -448px;
}
.lifeform-icon.lifeform-tech11216, .lifeform-icon.lifeform-tech12216, .lifeform-icon.lifeform-tech13216, .lifeform-icon.lifeform-tech14216 {
    background-position-x: -480px;
}
.lifeform-icon.lifeform-tech11217, .lifeform-icon.lifeform-tech12217, .lifeform-icon.lifeform-tech13217, .lifeform-icon.lifeform-tech14217 {
    background-position-x: -512px;
}
.lifeform-icon.lifeform-tech11218, .lifeform-icon.lifeform-tech12218, .lifeform-icon.lifeform-tech13218, .lifeform-icon.lifeform-tech14218 {
    background-position-x: -544px;
}
</style>

<script lang="ts">
export default defineComponent({
    emits: [ "update:modelValue" ],
    data() {
        return {
            technologies: [
                '14201', '13202', '11203', '13203', '12204', '14206', '12216',
                '13205', '12208', '13209', '13212', '13214', '11214', '11215', '13208',
                '11209', '14209', '11210', '14216', '11216', '13215', '13216', '14214'
            ]
        }
    },
    computed: {
        fleet: {
            get(): Fleet {
                return this.modelValue;
            },
            set(value: Fleet) {
                this.$emit("update:modelValue", value)
            }
        },
        activeTechnologies(): string[] {
            let techs = this.fleet.lfresearch||{};
            return this.technologies.filter((val) => { return techs[val] })
        }
    }
})
</script>