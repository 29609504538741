import * as Comlink from 'comlink';

import type { Options } from ".";
import type { EntityInfo } from "../entityInfo";
import type { SimulationResult } from "../newsimulator/simulator";
import type { Settings } from "../settings";
import type { ProgressCallback, SimulationWorker } from "./workers/worker";
import type { Parties } from "../types";
import { CreateSimulationParty } from './loaders/party';


class Simulator {
    id: string;
    workers: Worker[];
    callback: ProgressCallback & Comlink.ProxyMarked;

    constructor() {
        this.id = crypto.randomUUID();
        this.workers = [];
        this.callback = Comlink.proxy<ProgressCallback>(() => {})
    }

    registerCallback(fn: ProgressCallback): void {
        this.callback = Comlink.proxy<ProgressCallback>(fn)
    }

    simulate(parties: Parties, entityInfo: EntityInfo, settings: Settings, options: Options): Promise<SimulationResult[]> {
        this.createWorkers(options);
        
        let simulations = settings.simulations;
        let simulationsPerThread = Math.max(Math.ceil(simulations/this.workers.length), 1);
        let promises: Promise<SimulationResult[]>[] = [];

        let attackers = CreateSimulationParty(parties.attackers, entityInfo, settings)
        let defenders = CreateSimulationParty(parties.defenders, entityInfo, settings)

        if (attackers.entities.reduce((prev, curr) => { return prev + Object.values(curr).length}, 0) == 0) {
            return new Promise((_, reject) => { reject(new Error('No attackers')) })
        }

        console.time('simulate');
        for (let i = 0; i < this.workers.length; i++) {
            if (simulations <= 0) {
                break;
            }

            let proxy = Comlink.wrap<SimulationWorker>(this.workers[i]);
            console.debug('starting worker', i, 'with', Math.min(simulationsPerThread, simulations), 'simulations')
            promises.push(proxy.simulate(
                Math.min(simulationsPerThread, simulations),
                attackers,
                defenders,
                this.callback
            ))
            simulations -= Math.min(simulationsPerThread, simulations)
        }

        return Promise.all(promises).then(results => {
            console.timeEnd('simulate')
            this.reset()
            return results.flat(1)
        })
    }

    createWorkers(options: Options) {
        let count = options.workerCount ? options.workerCount : Math.max(window.navigator.hardwareConcurrency - 2, 2);

        console.debug('using', count, 'workers')
        for (let i=0; i < count; i++) {
            let worker = new Worker(new URL('./workers/worker.ts', import.meta.url), { type: 'module' })
            this.workers.push(worker);
        }
    }

    reset() {
        let i = 0;
        while(this.workers.length > 0) {
            console.debug('terminating worker', i)
            let worker = this.workers.pop()
            worker?.terminate()
            i++
        }
    }
}

export { Simulator }