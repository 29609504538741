<script setup lang="ts">
import { defineComponent } from 'vue';
import { PartyMembers, type Fleet } from '@/lib/types';
import type { CalculationResult, CalculationResults } from '@/lib/calculator/result';
import { ResultCases } from '@/lib/calculator/calculator'
import type { Settings } from '@/lib/settings';

import ResultTablePL from './ResultTablePL.vue';
import ResultTablePlanet from './ResultTablePlanet.vue';
import { GetCargoCapacity } from '@/lib/trashsim/entity_helpers';
import { GetEntitiesFromVersion, type EntityInfo } from '@/lib/entityInfo';

export interface Emits {
    (e: 'update:modelValue', value: CalculationResult): void
}

export interface Props {
    modelValue: CalculationResult | null | undefined
    results: CalculationResults | null | undefined
    attacker: Fleet
    defender: Fleet
    settings: Settings
}

const emit = defineEmits<Emits>()
const props = defineProps<Props>()
</script>

<template>
    <div id="results" :class="{active: result != null}">
        <div id="results-cases">
            <ul>
                <li
                    v-for="resultCase in ResultCases" :id="'results-case-'+resultCase"
                    v-bind:class="{active: results != null && result == results.cases[resultCase]}"
                    @click="selectCase(resultCase)"
                >
                    <span></span>
                </li>
            </ul>
        </div>
        <div class="results-tables">
            <!-- Result -->
            <div id="simulation-result" class="result-table">
                <table>
                    <thead>
                        <tr>
                            <th colspan=3>Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="fixed">Attackers win</td>
                            <td v-if="result == null">/</td>
                            <td v-else>{{ formatOutcome(results?.outcome.attackers || 0) }}</td>
                        </tr>
                        <tr>
                            <td class="fixed">Defenders win</td>
                            <td v-if="result == null">/</td>
                            <td v-else>{{ formatOutcome(results?.outcome.defenders || 0) }}</td>
                        </tr>
                        <tr>
                            <td class="fixed">Draw</td>
                            <td v-if="result == null">/</td>
                            <td v-else>{{ formatOutcome(results?.outcome.draw || 0) }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Rounds:</th>
                            <td v-if="result == null">/</td>
                            <td v-else>{{ result.rounds }}</td>
                        </tr>
                        <tr>
                            <th>Tactical retreat:</th>
                            <td>- : -</td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <!-- P&L tables -->
            <ResultTablePL
                v-for="(party) in PartyMembers"
                :party="party"
                :profits="result?.profits[party]"
                :loses="result?.loses[party]"
            />

            <!-- Debris -->
            <ResultTablePlanet
                :debris="result?.debris"
            />

            <!-- Plunder -->
            <div class="result-table result-table-plunder">
                <table class="resource-table">
                    <thead>
                        <tr>
                            <th colspan="6">Plunder</th>
                        </tr>
                        <tr>
                            <th colspan="2">Captured</th>
                            <th colspan="2">Possible</th>
                            <th colspan="2">Cargo's needed</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><div class="resource resource-metal"></div></td>
                            <td>{{ formatNumber(result?.plunder.metal || 0) }}</td>
                            <td><div class="resource resource-metal"></div></td>
                            <td>{{ formatNumber((defender.resources.metal || 0)*(settings.plunder/100)) }}</td>
                            <td>{{ $format.number(getEntitiesForPlunder("203")) }}</td>
                            <td>{{ $t('type.203') }}</td>
                        </tr>
                        <tr>
                            <td><div class="resource resource-crystal"></div></td>
                            <td>{{ formatNumber(result?.plunder.crystal || 0) }}</td>
                            <td><div class="resource resource-crystal"></div></td>
                            <td>{{ formatNumber((defender.resources.crystal || 0)*(settings.plunder/100)) }}</td>
                            <td>{{ $format.number(getEntitiesForPlunder("202")) }}</td>
                            <td>{{ $t('type.202') }}</td>
                        </tr>
                        <tr>
                            <td><div class="resource resource-deuterium"></div></td>
                            <td>{{ formatNumber(result?.plunder.deuterium || 0) }}</td>
                            <td><div class="resource resource-deuterium"></div></td>
                            <td>{{ formatNumber((defender.resources.deuterium || 0)*(settings.plunder/100)) }}</td>
                            <td>{{ $format.number(getEntitiesForPlunder("219")) }}</td>
                            <td>{{ $t('type.219') }}</td>
                        </tr>
                        <tr v-if="settings.lifeformsEnabled">
                            <td><div class="resource resource-food"></div></td>
                            <td>{{ formatNumber(result?.plunder.food || 0) }}</td>
                            <td><div class="resource resource-food"></div></td>
                            <td>{{ formatNumber((defender.resources.food || 0)*(settings.plunder/100)) }}</td>
                            <!-- <td>{{ $format.number(getEntitiesForPlunder("219")) }}</td>
                            <td>{{ $t('type.219') }}</td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- reaper data -->
            <!-- <div class="result-table result-table-debris">
                
            </div> -->
        </div>
    </div>
</template>

<script lang="ts">
export default defineComponent({
    data() {
        return {
            resultCases: [
                "average",
                "attackersBest",
                "attackersWorst",
                "defendersBest",
                "defendersWorst",
                "recyclersHighest"
            ],
            formatter: new Intl.NumberFormat("nl-NL", {})
        };
    },
    computed: {
        result: {
            get(): (CalculationResult | null | undefined) {
                return this.modelValue;
            },
            set(value: CalculationResult) {
                this.$emit("update:modelValue", value);
            }
        },
        entities(): EntityInfo {
            return GetEntitiesFromVersion(this.settings.version)
        }
    },
    methods: {
        getEntitiesForPlunder(entity: string): number {
            let sum = Math.ceil(
                (this.defender.resources.metal || 0) +
                (this.defender.resources.crystal || 0) +
                (this.defender.resources.deuterium || 0)
            )

            if (this.settings.lifeformsEnabled) {
                sum += (this.defender.resources.food || 0)
            }

            let cap = GetCargoCapacity(this.entities[entity], this.attacker.techs, this.settings)

            return Math.ceil((sum * (this.settings.plunder/100)) / cap)
        },
        selectCase(resultCase: string) {
            if (this.results != null) {
                this.result = this.results.cases[resultCase];
            }
        },
        formatOutcome(value: number): string {
            let result = Math.round(value / ((this.results?.outcome.attackers || 0) +
                (this.results?.outcome.defenders || 0) +
                (this.results?.outcome.draw || 0)) * 100);
            return (result > 0) ? `${result}%` : "/";
        },
        formatNumber(value: number, zero?: string): string {
            return (value == 0) ? (zero || "/") : this.formatter.format(Math.round(value));
        },
        formatPercent(value: number): string {
            return (value == 0) ? "/" : this.formatNumber(value) + "%";
        }
    }
})
</script>