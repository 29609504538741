import { createRouter, createWebHistory } from 'vue-router'

import SimulatorView from '../views/SimulatorView.vue'
import ContributorsView from '../views/ContributorsView.vue'
import LocalePicker from '@/components/LocalePicker.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home-en',
      components: {
        default: SimulatorView,
        LocalePicker
      }
    },
    {
      path: '/:locale',
      name: 'home',
      components: {
        default: SimulatorView,
        LocalePicker
      }
    },
    {
      path: '/:locale/contributors',
      name: 'contributors',
      components: {
        default: ContributorsView,
        LocalePicker
      }
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AboutView.vue')
    },
    {
      path: '/benchmark',
      name: 'benchmark',
      component: () => import('../views/BenchView.vue')
    }
  ],
  strict: true
})

export default router
