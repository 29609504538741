import type { Wave } from "./types";
import type { PartyEntityCount } from "../simulator/worker";
import type { Parties } from "../types";

export function NewWaveFromWave(wave: Wave): Wave {
    // this kinda sucks, but it's the easiest way to make a deep copy
    let parties: Parties = JSON.parse(JSON.stringify(wave.parties))
    let result = wave.result

    if (result == null) {
        console.error('result is null, this shouldn\'t happen')
        return <Wave>{}
    }

    let party: keyof PartyEntityCount;
    for (party in parties) {
        for (const index in parties[party].fleets) {
            console.log('next wave', party, index, parties[party].fleets[index].isDefender)
            // set the ships
            for (const entity in parties[party].fleets[index].ships) {
                parties[party].fleets[index].ships[entity] = result.entitiesRemaining[party][index][entity]
            }

            if (parties[party].fleets[index].isDefender) {
                // set the resources left
                if (result.outcome.attackers > 0) {
                    parties[party].fleets[index].resources.metal = (parties[party].fleets[index].resources.metal || 0) - result.plunder.metal
                    parties[party].fleets[index].resources.crystal = (parties[party].fleets[index].resources.crystal || 0) - result.plunder.crystal
                    parties[party].fleets[index].resources.deuterium = (parties[party].fleets[index].resources.deuterium || 0) - result.plunder.deuterium
                    parties[party].fleets[index].resources.food = (parties[party].fleets[index].resources.food || 0) - result.plunder.food
                }
                
                // set the defences
                for (const entity in parties[party].fleets[index].defences) {
                    // need to fix the rebuild factor
                    parties[party].fleets[index].defences[entity] = result.entitiesRemaining[party][index][entity]
                }
            }
        }
    }

    return {
        parties: parties,
        results: null,
        result: null
    }
}