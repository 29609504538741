<script setup lang="ts">
import type { Settings } from '@/lib/settings';
import type { Wave } from '@/lib/trashsim';
import { defineComponent, toRaw } from 'vue';

export interface Props {
    wave: Wave
    settings: Settings
}

export interface Emits {
    (e: 'simulate', value: Event): void
}

const props = defineProps<Props>()
const emits = defineEmits<Emits>()
</script>

<template>
    <div class="top-content">
        <div id="simulate-button" class="btn btn-light" @click="simulate">{{ $t('home.simulate') }}</div>
        <div id="share-button" class="btn btn-light btn-disabled btn-top" @click="share"></div>
        <div id="save-button" class="btn btn-light btn-disabled btn-top"></div>
    </div>
</template>

<script lang="ts">
export default defineComponent({
    methods: {
        async simulate(event: Event) {
            this.$emit('simulate', event)
        },
        async share() {
            console.log(toRaw(this.wave))
            console.log(toRaw(this.settings))
            this.$api.share({
                attackers: this.wave.parties.attackers,
                defenders: this.wave.parties.defenders,
                settings: this.settings
            })
        }
    }
})
</script>