<script setup lang="ts">
import type { Wave } from '@/lib/trashsim';
import { defineComponent } from 'vue';

export interface Emits {
    (e: 'update:modelValue', value: number): void
    (e: 'submit'): void
    (e: 'next'): void
    (e: 'clear'): void
}

export interface Props {
    modelValue: number
    waves: Wave[]
}

const emit = defineEmits<Emits>()
const props = defineProps<Props>()

</script>

<template>
    <div id="actions-bottom">
        <div id="simulate-button-bottom" class="btn btn-light" @click="$emit('submit')">{{ $t('home.simulate') }}</div>
        <div id="wave-buttons">
            <div class="btn btn-light btn-wave" v-bind:class="{active: modelValue > 0}" @click="previousWave">
                &lt; {{ $t('home.wave.previous') }}
            </div>
            <div id="wave-current" class="btn btn-light btn-wave active">
                {{ modelValue + 1 }}
            </div>
            <div class="btn btn-light btn-wave" v-bind:class="{active: isNextAllowed}" @click="newWave">
                {{ $t('home.wave.next') }} &gt;
            </div>
            <div id="wave-clear" class="btn btn-light btn-wave" v-bind:class="{active: waves.length > 1}" @click="clearWaves">
                x {{ $t('home.waves.clear') }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default defineComponent({
    computed: {
        currentWave: {
            get(): number {
                return this.modelValue
            },
            set(value: number) {
                this.$emit("update:modelValue", value)
            }
        },
        isNextAllowed(): boolean {
            return (
                this.modelValue - 1 < this.waves.length &&
                this.waves[this.modelValue].result != null
            )
        },
    },
    methods: {
        newWave() {
            console.debug(this.waves)
            if (this.isNextAllowed) {
                console.debug('newWave button pressed')
                this.$emit('next')
            }
        },
        previousWave() {
            if (this.modelValue > 0) {
                this.$emit('update:modelValue', this.modelValue-1)
            }
        },
        clearWaves() {
            this.$emit('clear')
        }
    }
})
</script>