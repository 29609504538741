import type { Settings } from "../settings";
import type { Outcome, PartyEntityCount, SimulationResult } from "../simulator/worker";
import type { EntityInfo } from "../entityInfo/type";
import { PlayerClass, type Fleet, type Parties, type Party } from "../types";

export type Resources = {
    metal: number
    crystal: number
    deuterium: number
    food: number
}

export type DebrisResult = {
    overall: DebrisResources
    reaper: {
        attackers: DebrisResources
        defenders: DebrisResources
    }
    remaining: DebrisResources
    recyclers: number
}

export type DebrisResources = {
    metal: number
    crystal: number
    deuterium: number
    total: number
}

type ReaperData = {
    attackers: {
        count: number
        capacity: number
    }
    defenders: {
        count: number
        capacity: number
    }
}

export type ResourceData = {
    attackers: DebrisResources
    defenders: DebrisResources
}

type PartyKeys = keyof PartyEntityCount

export type CalculationResults = {
    cases: { [key: string]: CalculationResult }
    outcome: Outcome
}

export class CalculationResult {
    outcome: Outcome;
    rounds: number;
    entitiesLost: PartyEntityCount
    entitiesRemaining: PartyEntityCount
    
    debris: DebrisResult
    loses: ResourceData
    plunder: Resources
    profits: ResourceData
    moonChance: number

    constructor(
        simulation: SimulationResult,
        entities: EntityInfo,
        settings: Settings,
        resources: Resources,
        canPlunder: boolean,
        engineer: boolean,
        parties: Parties
    ) {
        this.outcome = simulation.outcome;
        this.rounds = simulation.rounds;
        this.entitiesLost = simulation.lost;
        this.entitiesRemaining = simulation.remaining;

        // run the calculations
        this.debris = this.calculateDebris(entities, settings, engineer, parties)
        this.loses = this.calculateLoses(entities)
        this.plunder = this.calculatePlunder(entities, settings, resources, canPlunder, parties)
        // TODO: add profits
        this.profits = this.calculateProfits(parties)
        this.moonChance = this.calculateMoonChance()
    }

    calculateDebris(entities: EntityInfo, settings: Settings, engineer: boolean, partiesFleetInfo: Parties): DebrisResult {
        // FIXME calculate DeutToDef
        let metal = 0, crystal = 0, deuterium = 0;

        let fleetDebris = settings.fleetToDebris / 100;
        let defenceDebris = settings.defenceToDebris / 100;
        let defenceLoses = 1 - (settings.defenceRepair / 100);

        if (engineer) {
            defenceLoses /= 2;
        }

        // sometimes I really hate typescript
        let party: keyof PartyEntityCount;
        for (party in this.entitiesLost) {
            for (let index in this.entitiesLost[party]) {
                for (let entity in this.entitiesLost[party][index]) {
                    let toDebris = parseInt(entity) >= 400 ? defenceDebris * defenceLoses : fleetDebris;
                    metal += (this.entitiesLost[party][index][entity] * entities[entity].resources.metal) * toDebris;
                    crystal += (this.entitiesLost[party][index][entity] * entities[entity].resources.crystal) * toDebris;

                    if (settings.deuteriumInDebris) {
                        deuterium += (this.entitiesLost[party][index][entity] * entities[entity].resources.deuterium) * toDebris;
                    }
                }
            }
            this.entitiesLost[party]
        }

        // prepare results
        let result: DebrisResult = {
            overall: {
                metal: metal,
                crystal: crystal,
                deuterium: deuterium,
                total: metal + crystal + deuterium
            },
            reaper: {
                attackers: { metal: 0, crystal: 0, deuterium: 0, total: 0 },
                defenders: { metal: 0, crystal: 0, deuterium: 0, total: 0 }
            },
            remaining: { metal: 0, crystal: 0, deuterium: 0, total: 0 },
            recyclers: 0
        }

        if (settings.combatDebrisFieldLimit > 0) {
            let maxDebrisPercentage = settings.combatDebrisFieldLimit / 100;
            let maxDebrisHarvest = Math.floor(maxDebrisPercentage * result.overall.total);
            let debrisMetalRatio = result.overall.metal / result.overall.total;
            let debrisCrystalRatio = result.overall.crystal / result.overall.total;
            let debrisDeuteriumRatio = result.overall.deuterium / result.overall.total;
            // FIXME add deut ratio

            let reapers = this.calculateReaperData(entities, settings, partiesFleetInfo);
            for (party in reapers) {
                if (reapers[party].count > 0) {
                    const canHavest = maxDebrisHarvest > reapers[party].capacity ? reapers[party].capacity : maxDebrisHarvest;

                    result.reaper[party].metal = Math.floor(canHavest * debrisMetalRatio);
                    result.reaper[party].crystal = Math.floor(canHavest * debrisCrystalRatio);
                    result.reaper[party].deuterium = Math.floor(canHavest * debrisDeuteriumRatio);
                    result.reaper[party].total = result.reaper[party].metal + result.reaper[party].crystal + result.reaper[party].deuterium;
                }
            }
        }

        result.remaining.metal = result.overall.metal - result.reaper.attackers.metal - result.reaper.defenders.metal;
        result.remaining.crystal = result.overall.crystal - result.reaper.attackers.crystal - result.reaper.defenders.crystal;
        result.remaining.deuterium = result.overall.deuterium - result.reaper.attackers.deuterium - result.reaper.defenders.deuterium;
        result.remaining.total = result.overall.total - result.reaper.attackers.total - result.reaper.defenders.total;

        result.recyclers = Math.ceil(result.remaining.total / this.getEntityCargoCapacity(entities, "209", 1, settings, partiesFleetInfo.attackers.fleets[0]))

        return result
    }

    calculateLoses(entities: EntityInfo): ResourceData {
        let result: ResourceData = {
            attackers: { metal: 0, crystal: 0, deuterium: 0, total: 0 },
            defenders: { metal: 0, crystal: 0, deuterium: 0, total: 0 }
        }

        let party: PartyKeys;
        for(party in this.entitiesLost) {
            for(let index in this.entitiesLost[party]) {
                for (let entity in this.entitiesLost[party][index]) {
                    result[party].metal = (result[party].metal || 0) + this.entitiesLost[party][index][entity] * entities[entity].resources.metal;
                    result[party].crystal = (result[party].crystal || 0) + this.entitiesLost[party][index][entity] * entities[entity].resources.crystal;
                    result[party].deuterium = (result[party].deuterium || 0) + this.entitiesLost[party][index][entity] * entities[entity].resources.deuterium;
                }
            }
        }

        result.attackers.total = result.attackers.metal + result.attackers.crystal + result.attackers.deuterium
        result.defenders.total = result.defenders.metal + result.defenders.crystal + result.defenders.deuterium

        return result
    }

    calculatePlunder(entities: EntityInfo, settings: Settings, resources: Resources, canPlunder: boolean, parties: Parties): Resources {
        let capacity = 0;
        // we need to clone this
        let canBePlundered: Resources = {
            metal: resources.metal,
            crystal: resources.crystal,
            deuterium: resources.deuterium,
            food: resources.food
        }

        let result: Resources = { metal: 0, crystal: 0, deuterium: 0, food: 0 };

        if (canPlunder) {
            let resource: keyof Resources;
            for (resource in canBePlundered) {
                canBePlundered[resource] = (canBePlundered[resource] || 0) * (settings.plunder / 100);
            }

            for (let i in this.entitiesRemaining.attackers) {
                for (let entity in this.entitiesRemaining.attackers[i]) {
                    capacity += this.getEntityCargoCapacity(
                        entities,
                        entity,
                        this.entitiesRemaining.attackers[i][entity],
                        settings,
                        parties.attackers.fleets[i],
                    );
                }
            }

            let resources: (keyof Resources)[] = (settings.lifeformsEnabled)
                ? ['food', 'metal', 'crystal', 'deuterium']
                : ['metal', 'crystal', 'deuterium'];

            let sum = (r: Resources) => { return r.metal + r.crystal + r.deuterium + r.food }
            while (resources.length > 0) {
                resources.forEach((res, i) => {
                    result[res] += Math.min(
                        (capacity - sum(result)) / (resources.length - i),
                        (canBePlundered[res] - result[res])
                    )
                })
                resources.pop()
            }

            result.metal = Math.round(result.metal);
            result.crystal = Math.round(result.crystal);
            result.deuterium = Math.round(result.deuterium);
            result.food = Math.round(result.food);
        }

        return result
    }

    calculateMoonChance(): number {
        return Math.min(
            Math.floor(this.debris.overall.total / 100000),
            20
        )
    }

    calculateProfits(parties: Parties): ResourceData {
        let result: ResourceData = {
            attackers: { metal: 0, crystal: 0, deuterium: 0, total: 0 },
            defenders: { metal: 0, crystal: 0, deuterium: 0, total: 0 }
        }

        let party: PartyKeys;
        for (party in result) {
            let fuelConsumption = 0;

            for (let fleet in parties[party].fleets) {
                if (parties[party].fleets[fleet].fuelConsumption) {
                    fuelConsumption += parties[party].fleets[fleet].fuelConsumption;
                }
            }

            result[party].metal = this.debris.remaining.metal + this.debris.reaper[party].metal - this.loses[party].metal;
            result[party].crystal = this.debris.remaining.crystal + this.debris.reaper[party].crystal - this.loses[party].crystal;
            result[party].deuterium = this.debris.remaining.deuterium + this.debris.reaper[party].deuterium - this.loses[party].deuterium - fuelConsumption;
            
            if (party == "attackers") {
                result[party].metal += this.plunder.metal
                result[party].crystal += this.plunder.crystal
                result[party].deuterium += this.plunder.deuterium
            }

            result[party].total = result[party].metal + result[party].crystal + result[party].deuterium;
        }

        return result
    }

    calculateReaperData(entities: EntityInfo, settings: Settings, parties: Parties): ReaperData {
        let result: ReaperData = {
            attackers: { count: 0, capacity: 0 },
            defenders: { count: 0, capacity: 0 }
        }

        let party: PartyKeys
        for (party in this.entitiesRemaining) {
            for (let index in this.entitiesRemaining[party]) {
                if (this.entitiesRemaining[party][parseInt(index)][218] !== void 0) {
                    const reaperCount = this.entitiesRemaining[party][parseInt(index)][218];

                    result[party].count += reaperCount
                    result[party].count += this.getEntityCargoCapacity(entities, "218", reaperCount, settings, parties[party].fleets[index]);
                }
            }
        }

        return result
    }

    getEntityCargoCapacity(entities: EntityInfo, entity: string, entityCount: number, settings: Settings, fleet: Fleet): number {
        // only flying ships have cargo capacity
        let result: number = 0;
        let entityCapacity: number = entities[entity].cargoCapacity || 0;

        if (settings.cargoHyperspaceFactor > 0) {
            result = entityCapacity + entityCapacity * (settings.cargoHyperspaceFactor / 100) * (fleet.techs.hyperspace || 0);
        }

        // apply the collector bonus to cargo ships
        if (fleet.class == PlayerClass.Collector && entity in [202, 203]) {
            result += entityCapacity * (settings.minerBonusIncreasedCargoCapacityForTrandingShips / 100);
        }

        return result * entityCount
    }
}

export default CalculationResult