<script setup lang="ts">
import { defineComponent } from 'vue';
</script>

<template>
    <div id="trashsim-title-wrapper">
        <h1 id="trashsim-title" class="title">
            <a :href="'/'+$i18n.locale">{{ $t('home.title') }}</a>
        </h1>
    </div>
</template>

<script lang="ts">
export default defineComponent({})
</script>