<script setup lang="ts">
import type CalculationResult from '@/lib/calculator/result';
import { defineComponent } from 'vue';

export interface Props {
    modelValue: any,
    label: string,
    result?: any,
    type?: string,
    value?: any,
    values?: any[] | Object,
    trueValue?: any,
    falseValue?: any,
    suffix?: string
}

const props = withDefaults(defineProps<Props>(), {
    result: null,
    type: 'number',
    trueValue: true,
    falseValue: false
})
</script>

<template>
    <label>
        <span class="label-text">{{ label }}</span>
        <span
            v-if="result != null"
            class="entity-remaining"
        >{{ $format.number(result, '0')}} ← </span>
        <input
            v-if="type == 'number'"
            min="0"
            v-model="model"
            type="number"
            @keyup.enter="submit($event)"
        />
        <input type="text"
            v-if="type == 'text'"
            v-model="model"
            @keyup.enter="submit($event)"
        />
        <input
            v-else-if="type == 'checkbox'"
            v-model="model"
            type="checkbox"
            :true-value="trueValue"
            :false-value="falseValue"
        />
        <input
            v-else-if="type == 'radio'"
            type="radio"
            v-model="model"
            :value="value"
        />
        <select
            v-else-if="type == 'select'"
            v-model="model"
        >
            <option v-for="value in values" :value="value">
                {{ value }}{{ suffix }}
            </option>
        </select>
    </label>
</template>

<script lang="ts">
export default defineComponent({
    emits: [
        'update:modelValue',
        'submit'
    ],
    computed: {
        model: {
            get(): any {
                return this.modelValue
            },
            set(value: any) {
                this.$emit('update:modelValue', value)
            }
        },
    },
    methods: {
        submit(event: KeyboardEvent) {
            this.$emit('submit', event)
        }
    }
})
</script>

<style scoped>
input[type="text"] {
    width: 21% !important;
}
</style>