<script setup lang="ts">
import { defineComponent } from 'vue';

</script>

<template>
    <div id="header-lang" class="clearfix">
        <div :data-code="currentLocale"></div>
        <ul>
            <li
                v-for="loc in $i18n.availableLocales"
                class="switch-lang" :data-code="loc"
                v-bind:class="{selected: loc == currentLocale}"
                @click="switchLocale(loc)"
            >
                <span>{{ names[loc as keyof typeof names] }}</span>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
export default defineComponent({
    data() {
        return {
            names: {
                'en': "English",
                'nl': "Nederlands",
                'de': "Deutsch",
                'da': "Dansk",
                'pl': "Polski",
                'tr': "Türkçe",
                'hr': "Hrvatski",
                'sv': "Svenska",
                'pt': "Português",
                'pt-BR': "Português (Brasil)",
                'it': "Italiano",
                'es': "Español",
                'el': "Ελληνικά",
                'fr': "Français",
                'zh': "正體中文",
                'ro': "Română",
                'cs': "Čeština",
                'hu': "Magyar",
                'ko': "한국어",
                'sk': "Slovenský",
                'ru': "ру́сский язы́к"
            }
        }
    },
    created() {
        if (this.$route.params.locale != undefined) {
            this.$i18n.locale = this.$route.params.locale
        }
    },
    computed: {
        currentLocale(): string | string[] {
            return this.$i18n.locale
        },
        locales(): any[]  {
            return this.$i18n.availableLocales
        }
    },
    methods: {
        switchLocale(locale: string | string[]) {
            console.log('setting locale to', locale)
            this.$router.push({
                name: 'home',
                params: { locale: locale },
                query: this.$router.currentRoute.value.query,
                hash: this.$router.currentRoute.value.hash
            })
            this.$i18n.locale = locale
        }
    }
})
</script>