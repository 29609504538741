import type { Entity, EntityInfo } from "./type";

import EntityInfoV7 from './v7.json' assert { type: "json" }
import EntityInfoV10 from './v10.json' assert { type: "json" }
import { OGameVersions } from "../settings";

export function GetEntitiesFromVersion(version: OGameVersions): EntityInfo {
    switch (version) {
        case OGameVersions.V10:
            return EntityInfoV10
    }

    return EntityInfoV7
}

export type {
    Entity,
    EntityInfo
}

export {
    EntityInfoV7,
    EntityInfoV10
}