<script setup lang="ts">
import AppBar from './components/AppBar.vue';

import { defineComponent } from 'vue';
import { RouterView } from 'vue-router'
</script>

<template>
    <AppBar />
    <header>
        <div id="header-links" class="clearfix">
            <RouterView name="LocalePicker" />
        </div>
    </header>

    <main>
        <RouterView />
    </main>
</template>

<script lang="ts">
export default defineComponent({})
</script>