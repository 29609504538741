import type { EntityInfo } from "./entityInfo/type";
import type { Settings } from "./settings";
import type { Coordinates, Entities, Fleet, Ships, Techs } from "./types";
import entityInfoV7 from './entityInfo/v7.json' assert { type: "json" }

const entities: EntityInfo = entityInfoV7

const drives: { [key: string] : keyof Techs } = {
    "202": "combustion",
    "203": "combustion",
    "204": "combustion",
    "205": "impulse",
    "206": "impulse",
    "207": "hyperspace",
    "208": "hyperspace",
    "209": "combustion",
    "210": "combustion",
    "211": "impulse",
    "212": "hyperspace",
    "213": "hyperspace",
    "214": "hyperspace",
    "215": "hyperspace",
    "218": "hyperspace",
    "219": "hyperspace"
}

function FuelConsumption(
    fleet: Fleet,
    target: Coordinates,
    settings: Settings,
    speedPercent: number,
    speed: number
): (number | null) {
    let distance = Distance(fleet.coordinates, target, settings)
    if (distance == null) {
        return null
    }

    let maxSpeed = getMaxSpeed(fleet, entities)
    if (maxSpeed == null) {
        return null
    }

    let duration = calcDuration(distance, maxSpeed, (speedPercent/10), speed)
    let speedValue = Math.max(0.5, duration * speed - 10)
    let consumption = 0;

    for (const entity in fleet.ships) {
        if (fleet.ships[entity] == null || fleet.ships[entity] == 0) {
            continue
        }

        let shipSpeed = speedForShip(entities, entity, fleet.techs)
        let shipConsumption = consumptionForShip(entities, entity) * settings.deuteriumSaveFactor
        let shipSpeedValue = 35000 / speedValue * Math.sqrt(distance * 10 / shipSpeed)
        consumption += Math.max(
            shipConsumption *
            (fleet.ships[entity] || 0) *
            distance / 35000 *
            Math.pow(shipSpeedValue / 10 + 1, 2),
            1
        )
    }

    return Math.round(consumption)
}

function Distance(c1: Coordinates, c2: Coordinates, settings: Settings): (number | null) {
    if (c1.galaxy == null || c2.galaxy == null) {
        return null
    }
    if (c1.galaxy != c2.galaxy) {
        let d = Math.abs(c1.galaxy - c2.galaxy)
        d = settings.donutGalaxy ? Math.min(d, settings.numberOfGalaxies - d) : d;
        return 20000 * d
    }

    if (c1.system == null || c2.system == null) {
        return null
    }
    if (c1.system != c2.system) {
        let d = Math.abs(c1.system - c2.system)
        d = settings.donutSystem ? Math.min(d, 499 - d) : d;
        return 2700 + 95 * d
    }
    
    if (c1.position == null || c2.position == null) {
        return null
    }
    if (c1.position != c2.position) {
        return 1000 + 5 * Math.abs(c1.position - c2.position)
    }

    return 5
}

function Duration(fleet: Fleet, target: Coordinates, speed: number, speedPercent: number, settings: Settings): (number | null) {
    let distance = Distance(fleet.coordinates, target, settings)
    if (distance == null) {
        return null
    }

    let maxSpeed = getMaxSpeed(fleet, entities)
    if (maxSpeed == null) {
        return null
    }

    return calcDuration(distance, maxSpeed, (speedPercent/10), speed)
}

function calcDuration(distance: number, maxSpeed: number, speedPercent: number, speed: number): number {
    return Math.max(
        Math.round((35000 / speedPercent * Math.sqrt(distance * 10 / maxSpeed) + 10) / speed),
        1
    );
}

function getMaxSpeed(fleet: Fleet, entities: EntityInfo): (number | null) {
    let result = 10000000;
    for (const entity in fleet.ships) {
        if ((fleet.ships[entity] || 0) > 0) {
            result = Math.min(result, speedForShip(entities, entity, fleet.techs))
        }
    }
    if (result == 10000000) {
        return null
    }
    return result
}

function consumptionForShip(entities: EntityInfo, entity: string): number {
    return entities[entity].fuelUsage || 0
}

function speedForShip(entities: EntityInfo, entity: string, techs: Techs): number {
    let speed = entities[entity].speed || 0;
    let drive = drives[entity]

    // impulse small cargo
    if (entity == "202" && (techs["impulse"] || 0) >= 5) {
        speed = 10000
        drive = "impulse"
    }

    // impulse recyclers
    if (entity == "209" && (techs["impulse"] || 0) >= 17) {
        speed = 4000
        drive = "impulse"
    }

    // hyper recyclers
    if (entity == "209" && (techs["hyperspace"] || 0) >= 15) {
        speed = 6000
        drive = "hyperspace"
    }

    // hyper bombers
    if (entity == "211" && (techs["hyperspace"] || 0) >= 8) {
        speed = 5000
        drive = "hyperspace"
    }

    let factor = getDriveFactor(drive)

    return speed * (1 + (techs[drive] || 0) * factor)
}

function getDriveFactor(drive: keyof Techs): number {
    if (drive == "combustion") {
        return 0.1
    }
    if (drive == "impulse") {
        return 0.2
    }
    if (drive == "hyperspace") {
        return 0.3
    }

    return 0
}

export {
    FuelConsumption,
    Distance,
    Duration
}