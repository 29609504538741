<script setup lang="ts">
import type { DebrisResult } from '@/lib/calculator/result';
import { defineComponent } from 'vue';

export interface Props {
    debris: DebrisResult | undefined | null
}

const props = defineProps<Props>()
</script>

<template>
    <div class="result-table">
        <table>
            <thead>
                <tr>
                    <th colspan="3">{{ $t('home.result.planet') }}</th>
                </tr>
                <tr>
                    <th colspan="2">{{ $t('home.result.debris.title') }}</th>
                    <th>{{ $t('home.result.recyclers') }}</th>
                </tr>
            </thead>
            <tbody>
            <tr>
                <td><div class="resource resource-metal"></div></td>
                <td>{{ $format.number(debris?.remaining.metal) }}</td>
                <td rowspan="3" id="result-moonchance">{{ $format.number(debris?.recyclers) }}</td>
            </tr>
            <tr>
                <td><div class="resource resource-crystal"></div></td>
                <td>{{ $format.number(debris?.remaining.crystal) }}</td>
            </tr>
            <tr>
                <td><div class="resource resource-deuterium"></div></td>
                <td>{{ $format.number(debris?.remaining.deuterium) }}</td>
            </tr>
            </tbody>
            <tfoot>
            <tr class="total">
                <td><div class="fixed resource-total">=</div></td>
                <td>{{ $format.number(debris?.remaining.total) }}</td>
                <td class="result-recyclers-hst fixed"></td>
            </tr>
            </tfoot>
        </table>
    </div>
</template>

<script lang="ts">
export default defineComponent({})
</script>