import type { SpyReport } from "./types"
import type { App, Plugin } from "vue"

interface ApiOptions {
    base_url?: string
}

export class Client {
    base_url: string

    constructor(base_url?: string) {
        if (base_url === undefined) {
            base_url = '/api'
        }
        this.base_url = base_url
    }

    async spy(key: string): Promise<SpyReport> {
        return fetch(`${this.base_url}/v3/spy/${key}`)
            .then(response => response.json())
    }

    async share(data: any): Promise<any> {
        // return fetch(`${this.base_url}/v2/share`, {
        return fetch('http://127.0.0.1:8787/api/v3/share', {
            method: "POST",
            body: JSON.stringify(data)
        }).then(response => response.json())
    }
}

declare module '@vue/runtime-core' {
    export interface ComponentCustomProperties {
        $api: Client
    }
}

export const api: Plugin = {
    install: (app: App, options: ApiOptions) => {
        app.config.globalProperties.$api = new Client(options.base_url)
        // Object.defineProperty(app.config.globalProperties, '$api', typeof Client)
    }
}