<script setup lang="ts">
import type { Fleet } from '@/lib/types';
import { defineComponent } from 'vue';
import SimulateInput from './SimulateInput.vue';
import type { Settings } from '@/lib/settings';
import type CalculationResult from '@/lib/calculator/result';

export interface Props {
    modelValue: Fleet
    settings: Settings
    result: CalculationResult | null | undefined
}

const props = defineProps<Props>()
</script>

<template>
    <div id="planet-column" class="inner-column">
        <div class="content">
            <div id="ipm-simulate"></div>
            <h3>{{ $t('home.planet.title') }}</h3>
            <div id="defender-0-defence">
                <h4>{{ $t('home.planet.resources.label') }}</h4>
                <div class="clearfix">
                    <div class="planet-resource" v-bind:class="{ 'lifeforms-enabled': settings.lifeformsEnabled }">
                        <label>
                            <span class="labels-text">{{ $t('home.planet.resources.metal') }}</span>
                            <input type="number" v-model="defender.resources.metal" />
                        </label>
                    </div>
                    <div class="planet-resource" v-bind:class="{ 'lifeforms-enabled': settings.lifeformsEnabled }">
                        <label>
                            <span class="labels-text">{{ $t('home.planet.resources.crystal') }}</span>
                            <input type="number" v-model="defender.resources.crystal" />
                        </label>
                    </div>
                    <div class="planet-resource" v-bind:class="{ 'lifeforms-enabled': settings.lifeformsEnabled }">
                        <label>
                            <span class="labels-text">{{ $t('home.planet.resources.deuterium') }}</span>
                            <input type="number" v-model="defender.resources.deuterium" />
                        </label>
                    </div>
                    <div class="planet-resource" v-if="settings.lifeformsEnabled" v-bind:class="{ 'lifeforms-enabled': settings.lifeformsEnabled }">
                        <label>
                            <span class="labels-text">Food:</span>
                            <input type="number" v-model="defender.resources.food" />
                        </label>
                    </div>
                </div>
                <!-- TODO add the result for the remaining def -->
                <h4>{{ $t('home.planet.defence') }}</h4>
                <ul class="simulate-values">
                    <li class="clearfix" v-for="(_, type) in defender.defences">
                        <SimulateInput
                            v-model="defender.defences[type]"
                            :label="$t(`type.${type}`)"
                            :type="(type == 407 || type == 408) ? 'checkbox' : 'number'"
                            :result="result && defender.defences[type] ? result?.entitiesRemaining.defenders[0][type] || 0 : null"
                            :true-value=1
                            @submit="submit"
                        />
                    </li>
                    <li class="clearfix" v-for="(type) in [212, 217]">
                        <SimulateInput
                            v-model="defender.ships[type]"
                            :label="$t(`type.${type}`)"
                        />
                    </li>
                    <li class="clearfix">
                        <SimulateInput v-model="defender.engineer" :label="$t('home.planet.engineer')" type="checkbox" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default defineComponent({
    emits: ['update:modelValue', 'submit'],
    data() {
        return {}
    },
    computed: {
        defender: {
            get(): Fleet {
                return this.modelValue
            },
            set(value: Fleet) {
                this.$emit('update:modelValue', value)
            }
        }
    },
    methods: {
        async submit(event: KeyboardEvent) {
            this.$emit('submit', event)
        },
    }
})
</script>