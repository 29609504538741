<script setup lang="ts">
import { getDefaultShipsList, NewFleet, PlayerClass, getDefaultDefences, NewFleetFromAPI, NewFleetFromAPIString } from '@/lib/types';
import type { Party, Fleet, Techs, PartyKey } from '@/lib/types';
import { defineComponent } from 'vue';
import SimulateInput from './SimulateInput.vue';
import type { CalculationResult } from '@/lib/calculator/result';
import { Duration, FuelConsumption } from '@/lib/flight';
import type { Settings } from '@/lib/settings';

import LifeformTechnologiesComponent from '@/components/LifeformTechnologiesComponent.vue';

export interface Props {
    modelValue: Party
    defender: Fleet
    settings: Settings
    title: PartyKey
    result: CalculationResult | null | undefined
}

const props = defineProps<Props>()
</script>

<template>
    <h3>
    {{ $t('home.party.'+title) }}
    <span :id="'live-retreater-'+title" class="live-retreat">{{ dash(null) }}</span>
    </h3>
    <div class="party-selection">
        <ul class="list clearfix">
            <li 
                v-for="(fleet, index) in party.fleets"
                v-bind:class="{selected: activeFleet == fleet}"
                @click="activeFleet = fleet"
            >{{ title.charAt(0).toUpperCase() }}{{ index+1 }}</li>
            <li class="add-player" @click="addFleet">+</li>
        </ul>
    </div>
    <div class="content">
        <div class="remove-player btn-close"
            v-if="party.fleets.length > 1"
            @click="removeActiveFleet"
        >x</div>
        <div class="party-api-wrapper">
            <label class="clearfix">
                {{ $t('home.player.api.label') }}: 
                <input class="input-sr-key" type="text" v-model="activeFleet.API"/>
            </label>
        </div>
        <div class="party-api-load btn btn-light" @click="loadAPI">{{ $t('home.player.api.load') }}</div>

        <div class="player-classes">
            <h4>{{ $t('home.player.class.title') }}</h4>
            <ul class="player-class-selection">
            <li v-for="playerClass in PlayerClass"
                :class="'player-class-'+playerClass"
                v-bind:class="{selected: activeFleet.class == playerClass}"
            >
                <SimulateInput 
                    :label="$t('home.player.class.'+playerClass)" v-model="activeFleet.class"
                    type="radio" :value="playerClass"
                />
            </li>
            </ul>
        </div>

        <div class="player-techs-wrapper">
            <!-- Combat -->
            <div class="player-techs">
                <h4>{{ $t('home.player.techs.combat') }}</h4>
                <ul class="simulate-values">
                    <li class="clearfix" v-for="tech in combatTechs()">
                        <SimulateInput
                            v-model="activeFleet.techs[tech]"
                            :label="$t('home.player.techs.'+tech.toString())"
                            type="number" @submit="submit"
                        />
                    </li>
                </ul>
            </div>

            <!-- Drives -->
            <div class="player-techs" v-if="!activeFleet.isDefender">
                <h4>{{ $t('home.player.techs.drives') }}</h4>
                <ul class="simulate-values">
                    <li class="clearfix" v-for="tech in driveTechs()">
                        <SimulateInput
                            v-model.number="activeFleet.techs[tech]"
                            :label="$t('home.player.techs.'+tech)"
                            type="number"
                            @submit="submit"
                        />
                    </li>
                </ul>
            </div>

            <!-- Hyperspacetech -->
            <div class="player-techs player-techs-hyper">
                <ul class="simulate-values">
                    <li class="clearfix">
                        <SimulateInput
                            v-model="activeFleet.techs.hyperspacetech"
                            :label="$t('home.player.techs.hyperspacetech')"
                            type="number"
                            @submit="submit"
                        />
                    </li>
                </ul>
            </div>
        </div>

        <LifeformTechnologiesComponent v-model="activeFleet" v-if="settings.lifeformsEnabled" />

        <h4>{{ $t('home.player.ships') }}</h4>
        <ul class="simulate-values">
            <li class="clearfix" v-for="(_, entity) in getDefaultShipsList()">
                <SimulateInput
                    v-model="activeFleet.ships[entity]"
                    :label="$t('type.'+entity)"
                    :result="result != null && activeFleet.ships[entity] ? result?.entitiesRemaining[title][getActiveFleetIndex()][entity] || 0 : null"
                    type="number"
                    @submit="submit"
                />
            </li>
        </ul>
        <div class="player-coordinates">
            <h4 class="clearfix">
                {{ $t('home.player.coords') }}:
                <div>
                    <input type="number" v-model="activeFleet.coordinates.galaxy" min="1" :max="settings.numberOfGalaxies" />
                    : 
                    <input type="number" v-model="activeFleet.coordinates.system" min="1" max="499" />
                    : 
                    <input type="number" v-model="activeFleet.coordinates.position" min="1" max="15" />
                </div>
            </h4>
            <h4 class="clearfix">
                {{ $t('home.player.speed') }}
                <div v-if="!activeFleet.isDefender">
                    <select v-model="activeFleet.speed">
                        <option
                            v-for="speed in getSpeeds(activeFleet.class)"
                            :value="speed"
                        >{{ speed }}%</option>
                    </select>
                </div>
                <div v-else>
                    <select disabled>
                        <option value="-">-</option>
                    </select>
                </div>
            </h4>
            <div class="clearfix player-flight-data">
                {{ $t('home.player.flighttime.label') }}
                <span>{{ dash(duration) }}</span>
            </div>
            <div class="clearfix player-flight-data">
                {{ $t('home.player.consumption') }}
                <span>{{ formatNumber(fuelConsumption || 0) }}</span>
            </div>
            <div class="clearfix player-flight-data">
                {{ $t('home.player.cargocapacity') }}
                <span>{{ dash(null) }}</span>
            </div>
            <div class="player-bottom-actions">
                <div class="clear-player btn btn-light" @click="clearPlayer()" >{{ $t('home.player.clear.player') }}</div>
                <div class="clear-player btn btn-light" @click="clearFleet()" >{{ $t('home.player.clear.entities') }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default defineComponent({
    emits: [
        "update:modelValue",
        "submit",
        "loadSettings"
    ],
    data() {
        return {
            selectFleet: 0
        };
    },
    computed: {
        party: {
            get(): Party {
                return this.modelValue;
            },
            set(value: Party) {
                this.$emit("update:modelValue", value);
            }
        },
        activeFleet: {
            get(): Fleet {
                return this.party.fleets[this.selectFleet]
            },
            set(value: Fleet) {
                console.log('set fleet', this.selectFleet, this.party.fleets.findIndex(fleet => fleet.ID == value.ID))
                this.selectFleet = this.party.fleets.findIndex(fleet => fleet.ID == value.ID)
            }
        },
        fuelConsumption(): (number | null) {
            if (this.activeFleet.ID == this.defender.ID) {
                return null
            }

            let speed = this.title == 'attackers' ? this.settings.fleetWarSpeed : this.settings.fleetHoldSpeed;

            let consumption = FuelConsumption(this.activeFleet, this.defender.coordinates, this.settings, this.activeFleet.speed, speed)

            if (consumption != null) {
                this.activeFleet.fuelConsumption = consumption;
            }
            return consumption
        },
        duration(): (string | null) {
            if (this.activeFleet.ID == this.defender.ID) {
                return null
            }
            return this.formatDuration(Duration(this.activeFleet, this.defender.coordinates, this.settings.fleetWarSpeed, this.activeFleet.speed, this.settings))
        }
    },

    methods: {
        addFleet() {
            this.party.fleets.push(NewFleet(false))
            this.selectFleet = this.party.fleets.length - 1
            console.log(this.party.fleets, this.selectFleet)
        },
        removeActiveFleet() {
            let index = this.getActiveFleetIndex();
            if (this.party.fleets.length > 0 && index >= 0) {
                this.party.fleets.splice(index, 1);
            }

            this.activeFleet = this.party.fleets[index == this.party.fleets.length ? this.party.fleets.length - 1 : index]
        },
        getActiveFleetIndex(): number {
            return this.party.fleets.findIndex(fleet => fleet.ID == this.activeFleet.ID)
        },
        clearPlayer() {
            let index = this.party.fleets.findIndex(fleet => fleet.ID == this.activeFleet.ID)
            this.party.fleets[index] = NewFleet(this.activeFleet.isDefender)
            this.activeFleet = this.party.fleets[index]
        },
        clearFleet() {
            this.activeFleet.ships = getDefaultShipsList()

            if (this.activeFleet.isDefender) {
                this.activeFleet.defences = getDefaultDefences();
                this.activeFleet.ships["212"] = null
                this.activeFleet.ships["217"] = null
            }
        },
        getSpeeds(playerClass: PlayerClass): number[] {
            if (playerClass == PlayerClass.General) {
                return [100, 95, 90, 85, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10, 5]
            }
            return [100, 90, 80, 70, 60, 50, 40, 30, 20, 10]
        },
        loadAPI() {
            if (this.activeFleet.API == null || this.activeFleet.API == undefined) {
                return
            }

            if (/sr-[a-z]{2}-[\d]{1,3}-[a-f0-9]{40}/.test(this.activeFleet.API)) {
                console.debug('loading from api key')
                this.$api.spy(this.activeFleet.API)
                    .then(report => {
                        let fleet = NewFleetFromAPI(report, (this.title == 'defenders' && this.getActiveFleetIndex() == 0))
                        fleet.API = this.activeFleet.API
                        this.party.fleets[this.getActiveFleetIndex()] = fleet
                        this.$emit('loadSettings', report.server, report.loot_percentage);
                    })
            }
            else if (/coords;\d:[\d]{1,3}:[\d]{1,2}\|characterClassId;\d\|[\d\|;]+/.test(this.activeFleet.API)) {
                console.debug('loading from api string')
                let fleet = NewFleetFromAPIString(this.activeFleet.API, this.activeFleet.isDefender)
                fleet.API = this.activeFleet.API
                this.party.fleets[this.getActiveFleetIndex()] = fleet
            }
            else {
                console.error('unknown api key format')
            }
        },
        dash(value: any): (any) {
            if (value === null) {
                return "-";
            }
            return value;
        },
        async submit(event: KeyboardEvent) {
            this.$emit('submit', event)
        },
        driveTechs(): Array<keyof Techs> {
            return ['combustion', 'impulse', 'hyperspace'].map(v => v as keyof Techs)
        },
        combatTechs(): Array<keyof Techs> {
            return ['weapon', 'shield', 'armour'].map((v) => v as keyof Techs)
        },
        formatDuration(duration: (number | null)): (string | null) {
            if (duration == null) {
                return null
            }
            const hours = Math.floor(duration/3600).toString().padStart(2, "0")
            const minutes = Math.floor((duration%3600)/60).toString().padStart(2, "0")
            const seconds = Math.floor(duration%60).toString().padStart(2, "0")

            return `${hours}:${minutes}:${seconds}`
        },
        formatNumber(value: number, zero?: string): string {
            return (value == 0) ? (zero || '/') : new Intl.NumberFormat('nl-NL').format(Math.round(value))
        },
    }
})
</script>