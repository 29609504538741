import type { App, DirectiveBinding, VueElement } from "vue";

const tooltipDirective = (app: App) => {
    app.directive("tooltip", {
        mounted(el, binding) {
            init(el, binding)
        },
        updated(el, binding) {
            init(el, binding)
        }
    })
}

function init(el: VueElement, binding: DirectiveBinding) {
    let position = binding.arg || "top";
    let text = binding.value || "text";

    el.setAttribute("position", position);
    el.setAttribute("tooltip", text);
}

export default tooltipDirective