import { Settings } from "@/lib/settings";
import { type Wave } from "..";
import { PrefillV2Parser } from "./prefill_v2";
import { PrefillV1Parser } from "./prefill_v1";
import { getDefaultTechs, type Techs } from "@/lib/types";

export type Prefill = {
    waves: Wave[]
    settings?: Settings
    version?: number
    integration?: string
}

function LoadPrefill(hash: string): Prefill {
    let parsed = null;
    let version = 1;
    let integration = null;

    if (!hash.startsWith('#prefill=')) {
        throw new Error('bad prefill hash')
    }

    try {
        parsed = JSON.parse(atob(hash.substring(9)))
    } catch(err) {
        console.error('parse error in json')
        throw err
    }

    if (!(parsed instanceof Object)) {
        throw new Error('prefill root must be an object')
    }

    if ('version' in parsed) {
        version = parseInt(parsed['version'])
    }

    if ('integration' in parsed) {
        integration = parsed['integration']
    }

    switch(version) {
        case 1:
            console.debug('using v1 prefill')
            return new PrefillV1Parser(parsed).load()
        case 2:
            try {
                return new PrefillV2Parser(parsed).load()
            } catch (err) {
                console.error(`error loading prefill from ${integration}`)
                throw err
            }
        default:
            throw new Error(`bad prefill version ${version}`)
    }
}

const researchMappings = <{[key: string]: keyof Techs}>{
    "109": "armour",
    "110": "shield",
    "111": "weapon",
    "114": "hyperspacetech",
    "115": "combustion",
    "117": "impulse",
    "118": "hyperspace"
}

function GetPrefillTechs(prefill: {[key: string]: number}): Techs {
    let techs = getDefaultTechs()

    for (const key in prefill) {
        techs[researchMappings[key]] = prefill[key]
    }

    return techs
}

export {
    GetPrefillTechs,
    LoadPrefill
}