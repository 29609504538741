// import type { Party } from "./party"

import type { LifeformsBonuses, SpyReport } from "@/api/types";
import type CalculationResult from "./calculator/result";
import type { PartyEntityCount } from "./simulator/worker";
import { toRaw } from "vue";

export type Parties = {
    attackers: Party,
    defenders: Party
}

export const PartyMembers: PartyKey[] = ["attackers", "defenders"]

export type PartyKey = keyof Parties;

export type Party = {
    fleets: Fleet[]
}

export type Fleet = {
    ID: string
    API: string | null | undefined
    ships: Ships
    techs: Techs
    coordinates: Coordinates
    class: PlayerClass
    speed: number
    fuelConsumption: number

    // lifeforms
    lfresearch?: {[key: string]: number}
    lifeformsBonuses?: LifeformsBonuses

    // Defender fields
    isDefender: boolean
    defences: Defences
    resources: Resources
    engineer: boolean
}

export type Ships = {
    [key: string]: number | null;
}

export type Defences = {
    [key: string]: number | null;
}

export type Techs = {
    weapon: number | null
    shield: number | null
    armour: number | null
    hyperspacetech: number | null
    combustion: number | null
    impulse: number | null
    hyperspace: number | null
}

export type Coordinates = {
    galaxy: number | null,
    system: number | null,
    position: number | null,
}

export enum PlayerClass {
    None = "none",
    Collector = "collector",
    Discoverer = "discoverer",
    General = "general"
}

export type Resources = {
    metal: number | null
    crystal: number | null
    deuterium?: number | null
    food?: number | null
}

export type Entities = {
    [key: string]: number | null,
}

export function NewParties(): Parties {
    return {
        attackers: NewParty(),
        defenders: NewDefenders(),
    }
}

export function NewParty(): Party {
    return {
        fleets: [NewFleet(false)]
    }
}

export function NewDefenders(): Party {
    return {
        fleets: [NewFleet(true)]
    }
}

export function NewFleet(isDefender: boolean): Fleet {
    let ships = getDefaultShipsList();
    let defences: Defences = {};

    if (isDefender) {
        defences = getDefaultDefences();
        ships["212"] = null
        ships["217"] = null
    }

    let result = <Fleet>{
        ID: crypto.randomUUID(),
        API: null,
        class: PlayerClass.None,
        ships: ships,
        techs: {
            weapon: null,
            shield: null,
            armour: null,
            hyperspacetech: null,
            combustion: null,
            impulse: null,
            hyperspace: null
        },
        coordinates: {
            galaxy: null,
            system: null,
            position: null,
        },
        speed: 100,
        fuelConsumption: 0,
        isDefender: isDefender,
        engineer: false,
        resources: <Resources>{},
        defences: defences,
    }

    return result
}

export function PlayerClassFromNumber(c: number): PlayerClass {
    switch (c) {
        case 0:
            return PlayerClass.None
        case 1:
            return PlayerClass.Collector
        case 2:
            return PlayerClass.General
        case 3:
            return PlayerClass.Discoverer
    }

    return PlayerClass.None
}

export function NewFleetFromAPI(report: SpyReport, isDefender: boolean): Fleet {
    let techs = report.defender.research || {}

    let ships = getDefaultShipsList()
    if (report.defender.ships != undefined) {
        for (let entity in ships) {
            ships[entity] = report.defender.ships[entity] || null;
        }

        if (isDefender) {
            ships["212"] = report.defender.ships["212"] || null;
            ships["217"] = report.defender.ships["217"] || null;
        }
    }
    
    let result: Fleet = {
        ID: crypto.randomUUID(),
        API: null,
        class: PlayerClassFromNumber(report.defender.class),
        ships: report.defender.ships || {},
        techs: {
            weapon: techs["109"] || null,
            shield: techs["110"] || null,
            armour: techs["111"] || null,
            hyperspacetech: techs["114"] || null,
            combustion: techs["115"] || null,
            impulse: techs["117"] || null,
            hyperspace: techs["118"] || null
        },
        coordinates: {
            galaxy: report.defender.planet.galaxy,
            system: report.defender.planet.system,
            position: report.defender.planet.position,
        },
        speed: 100,
        fuelConsumption: 0,
        lfresearch: report.defender.lfresearch,
        lifeformsBonuses: report.defender.lifeformBonuses,
        isDefender: isDefender,
        engineer: false,
        resources: {
            metal: report.defender.resources?.metal || null,
            crystal: report.defender.resources?.crystal || null,
            deuterium: report.defender.resources?.deuterium || null,
            food: report.defender.resources?.food || null,
        },
        defences: getDefaultDefences(),
    }

    if (isDefender && report.defender.defence != undefined) {
        for (let entity in report.defender.defence) {
            result.defences[entity] = report.defender.defence[entity] || null;
        }
    }

    return result
}

export function NewFleetFromAPIString(api: string, isDefender: boolean): Fleet {
    let data = Object.fromEntries(api.split('|').map(kv => kv.split(';')))

    console.debug('api string data:', data)

    let ships = getDefaultShipsList()
    if (isDefender) {
        ships["212"] = null;
        ships["217"] = null;
    }
    for (const entity in ships) {
        ships[entity] = parseInt(data[entity], 10) || null;
    }

    let result = {
        ID: crypto.randomUUID(),
        API: api,
        class: PlayerClassFromNumber(parseInt(data["characterClassId"] || 0)),
        ships: ships,
        techs: {
            weapon: data["109"] || 0,
            shield: data["110"] || 0,
            armour: data["111"] || 0,
            hyperspacetech: data["114"] || 0,
            combustion: data["115"] || 0,
            impulse: data["117"] || 0,
            hyperspace: data["118"] || 0
        },
        coordinates: {
            galaxy: parseInt(data['coords'].split(':')[0]),
            system: parseInt(data['coords'].split(':')[1]),
            position: parseInt(data['coords'].split(':')[2]),
        },
        speed: 100,
        fuelConsumption: 0,
        isDefender: isDefender,
        engineer: false,
        resources: {
            metal: null,
            crystal: null,
            deuterium: null,
            food: null
        },
        defences: getDefaultDefences()
    }

    if (isDefender) {
        for (const entity in result.defences) {
            result.defences[entity] = parseInt(data[entity], 10) || null;
        }
    }

    return result
}

export function getDefaultShipsList(): Ships {
    return {
        "202": null,
        "203": null,
        "204": null,
        "205": null,
        "206": null,
        "207": null,
        "208": null,
        "209": null,
        "210": null,
        "211": null,
        "213": null,
        "214": null,
        "215": null,
        "218": null,
        "219": null
    }
}

export function getDefaultDefences(): Defences {
    return {
        "401": null,
        "402": null,
        "403": null,
        "404": null,
        "405": null,
        "406": null,
        "407": null,
        "408": null,
        "502": null
    }
}

export function getDefaultTechs(): Techs {
    return {
        weapon: null,
        shield: null,
        armour: null,
        hyperspacetech: null,
        combustion: null,
        impulse: null,
        hyperspace: null
    }
}

export function getDefaultResources(): Resources {
    return {
        metal: 0,
        crystal: 0,
        deuterium: 0,
        food: 0,
    }
}

export function getEntities(fleet: Fleet): Entities {
    let result: Entities = {}

    for (let type in fleet.ships) {
        result[type] = fleet.ships[type]
    }

    if (fleet.isDefender) {
        for (let type in fleet.defences) {
            result[type] = fleet.defences[type]
        }
    }

    return result
}

export function NewPartiesFromResult(parties: Parties, result: CalculationResult): Parties {
    let r: Parties = JSON.parse(JSON.stringify(parties))

    let party: keyof PartyEntityCount;
    for (party in r) {
        for (const index in r[party].fleets) {
            console.log('next wave', party, index, r[party].fleets[index].isDefender)
            // set the ships
            for (const entity in r[party].fleets[index].ships) {
                r[party].fleets[index].ships[entity] = result.entitiesRemaining[party][index][entity]
            }

            if (r[party].fleets[index].isDefender) {
                // set the resources left
                console.log('result outcomes', toRaw(result))
                if (result.outcome.attackers > 0) {
                    console.log('plunder', r[party].fleets[index].resources, result.plunder)
                    r[party].fleets[index].resources.metal = (r[party].fleets[index].resources.metal || 0) - result.plunder.metal
                    r[party].fleets[index].resources.crystal = (r[party].fleets[index].resources.crystal || 0) - result.plunder.crystal
                    r[party].fleets[index].resources.deuterium = (r[party].fleets[index].resources.deuterium || 0) - result.plunder.deuterium
                    r[party].fleets[index].resources.food = (r[party].fleets[index].resources.food || 0) - result.plunder.food
                }
                
                // set the defences
                for (const entity in r[party].fleets[index].defences) {
                    r[party].fleets[index].defences[entity] = result.entitiesRemaining[party][index][entity]
                }
            }
        }
    }

    return r
}