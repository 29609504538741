import { createI18n } from "vue-i18n/dist/vue-i18n.js"

import cs from './locales/cs.json'
import da from './locales/da.json'
import de from './locales/de.json'
import el from './locales/el.json'
import en from './locales/en.json'
import es from './locales/es.json'
import fr from './locales/fr.json'
import hr from './locales/hr.json'
import hu from './locales/hu.json'
import it from './locales/it.json'
import ko from './locales/ko.json'
import nl from './locales/nl.json'
import pl from './locales/pl.json'
import ptBR from './locales/pt-BR.json'
import pt from './locales/pt.json'
import ro from './locales/ro.json'
import ru from './locales/ru.json'
import sk from './locales/sk.json'
import sv from './locales/sv.json'
import tr from './locales/tr.json'
import zh from './locales/zh.json'

type MessageSchema = typeof en

export const i18n = createI18n<[MessageSchema], 'en'>({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        'cs': cs,
        'da': da,
        'de': de,
        'el': el,
        'en': en,
        'es': es,
        'fr': fr,
        'hr': hr,
        'hu': hu,
        'it': it,
        'ko': ko,
        'nl': nl,
        'pl': pl,
        'pt-BR': ptBR,
        'pt': pt,
        'ro': ro,
        'ru': ru,
        'sk': sk,
        'sv': sv,
        'tr': tr,
        'zh': zh,
    }
})