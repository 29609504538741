<script setup lang="ts">
import { OGameVersions, Settings } from '@/lib/settings';
import { defineComponent } from 'vue';
import SimulateInput from './SimulateInput.vue';

export interface Props {
    modelValue: Settings
}

const props = withDefaults(defineProps<Props>(), {})
</script>

<template>
    <div id="settings-column" class="inner-column">
        <div class="content">
            <h3 class="settings-title">{{ $t('home.settings.title') }}</h3>
            <ul class="simulate-values">
                <li class="clearfix" v-for="setting in shortSettings">
                    <SimulateInput
                        v-model="settings[setting.key]"
                        :label="$t(`home.settings.${setting.key}`)"
                        :type="setting.type"
                        :values="setting.values"
                        :suffix="setting.suffix"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
export default defineComponent({
    emits: ['update:modelValue'],
    data() {
        return {
            shortSettings: [
                {
                    key: 'simulations',
                    type: 'number',
                },
                {
                    key: 'plunder',
                    type: 'select',
                    values: [50, 75, 100],
                    suffix: '%'
                },
                {
                    key: 'fleetWarSpeed',
                    type: 'number'
                },
                {
                    key: 'fleetHoldSpeed',
                },
                {
                    key: 'fleetToDebris',
                    type: 'number'
                },
                {
                    key: 'defenceToDebris',
                    type: 'number'
                },
                {
                    key: 'numberOfGalaxies',
                    type: 'number'
                },
                {
                    key: 'deuteriumSaveFactor',
                    type: 'number'
                },
                {
                    key: 'cargoHyperspaceFactor',
                    type: 'number'
                },
                {
                    key: 'lifeformsEnabled',
                    type: 'checkbox'
                },
                {
                    key: 'deuteriumInDebris',
                    type: 'checkbox'
                },
                {
                    key: 'version',
                    type: 'select',
                    values: OGameVersions
                }
            ]
        }
    },
    computed: {
        settings: {
            get(): any {
                return this.modelValue
            },
            set(value: any) {
                this.$emit('update:modelValue', value)
            }
        }
    }
})
</script>