import { Settings } from "@/lib/settings";
import type { Wave } from "..";
import { GetPrefillTechs, type Prefill } from "./prefill";
import { type Fleet, NewFleet, type Parties, type Party } from "@/lib/types";

class PrefillV2Parser {
    raw: object

    constructor(raw: object) {
        this.raw = raw
    }

    load(): Prefill {
        return {
            waves: this.loadWaves(this.raw),
            settings: this.loadSettings(this.raw)
        }
    }

    loadWaves(raw: any): Wave[] {
        if (!('waves' in this.raw) || !(this.raw['waves'] instanceof Array)) {
            throw new Error('waves missing in prefill')
        }

        let waves: Wave[] = [];
        raw['waves'].forEach((element: any, index: number) => {
            waves.push(this.loadWave(element, index))
        });

        return waves
    }

    loadWave(raw: any, index: number): Wave {
        let parties: Parties = {
            attackers: { fleets: [] },
            defenders: { fleets: [] }
        }

        if ('attackers' in raw) {
            parties.attackers = this.loadParty(raw.attackers, false)
        }
        
        if ('defenders' in raw) {
            parties.defenders = this.loadParty(raw.defenders, index == 0)
        }

        return {
            parties: parties,
            result: null,
            results: null
        }
    }

    loadParty(raw: any, isDefender: boolean): Party {
        return {
            fleets: this.loadFleets(raw, isDefender)
        }
    }

    loadFleets(raw: any, isDefender: boolean): Fleet[] {
        if (!(raw instanceof Array)) {
            throw new Error('party needs to be an array')
        }
        
        let fleets: Fleet[] = [];
        raw.forEach((fleet: any, index: number) => {
            fleets.push(this.loadFleet(fleet, isDefender && index == 0))
        })

        return fleets
    }

    loadFleet(raw: any, isDefender: boolean): Fleet {
        let fleet = NewFleet(isDefender)

        if ('api' in raw) {
            fleet.API = raw['api']
            return fleet
        }

        if ('class' in raw) {
            fleet.class = raw['class']
        }

        if ('coordinates' in raw) {
            const cp = raw['coordinates'].split(':')
            fleet.coordinates = {
                galaxy: parseInt(cp[0]),
                system: parseInt(cp[1]),
                position: parseInt(cp[2])
            }
        }

        if ('speed' in raw) {
            fleet.speed = raw['speed']
        }

        if ('lfresearch' in raw) {
            fleet.lfresearch = raw['lfresearch']
        }

        if ('ships' in raw) {
            for (const entity in raw['ships']) {
                fleet.ships[entity] = raw['ships'][entity]
            }
        }

        if ('research' in raw) {
            const presearch: {[key: string]: number} = {}
            for (const key in raw.research) {
                presearch[key] = raw.research[key]
            }
            fleet.techs = GetPrefillTechs(presearch)
        }

        if ('lifeformsBonuses' in raw) {
            fleet.lifeformsBonuses = raw['lifeformsBonuses']
        }

        // all the options after this are only useful for the primary defender
        if (!isDefender) {
            return fleet
        }

        if ('engineer' in raw) {
            fleet.engineer = raw['engineer']
        }

        if ('resources' in raw) {
            fleet.resources = raw['resources']
        }

        if ('defences' in raw) {
            for (const entity in raw['defences']) {
                fleet.ships[entity] = raw['defences'][entity]
            }
        }
        
        return fleet
    }

    loadSettings(raw: any): Settings | undefined {
        if (!('settings' in this.raw)) {
            return undefined
        }

        if (!(this.raw['settings'] instanceof Object)) {
            return undefined
        }

        // this is pretty ugly
        let settings = new Settings();
        let psettings = <Settings>raw['settings'];

        if (psettings.simulations) {
            settings.simulations = psettings.simulations
        }

        if (psettings.plunder) {
            settings.plunder = psettings.plunder
        }

        if (psettings.fleetWarSpeed) {
            settings.fleetWarSpeed = psettings.fleetWarSpeed
        }

        if (psettings.fleetHoldSpeed) {
            settings.fleetHoldSpeed = psettings.fleetHoldSpeed
        }

        if (psettings.fleetToDebris) {
            settings.fleetToDebris = psettings.fleetToDebris
        }

        if (psettings.defenceToDebris) {
            settings.defenceToDebris = psettings.defenceToDebris
        }

        if (psettings.defenceRepair) {
            settings.defenceRepair = psettings.defenceRepair
        }

        if (psettings.numberOfGalaxies) {
            settings.numberOfGalaxies = psettings.numberOfGalaxies
        }

        if (psettings.deuteriumSaveFactor) {
            settings.deuteriumSaveFactor = psettings.deuteriumSaveFactor
        }

        if (psettings.cargoHyperspaceFactor) {
            settings.cargoHyperspaceFactor = psettings.cargoHyperspaceFactor
        }

        if (psettings.donutGalaxy) {
            settings.donutGalaxy = psettings.donutGalaxy
        }

        if (psettings.donutSystem) {
            settings.donutSystem = psettings.donutSystem
        }

        if (psettings.version) {
            settings.version = psettings.version
        }

        if (psettings.combatDebrisFieldLimit) {
            settings.combatDebrisFieldLimit = psettings.combatDebrisFieldLimit
        }

        if (psettings.minerBonusIncreasedCargoCapacityForTrandingShips) {
            settings.minerBonusIncreasedCargoCapacityForTrandingShips = psettings.minerBonusIncreasedCargoCapacityForTrandingShips
        }

        if (psettings.lifeformsEnabled) {
            settings.lifeformsEnabled = psettings.lifeformsEnabled
        }

        if (psettings.deuteriumInDebris) {
            settings.deuteriumInDebris = psettings.deuteriumInDebris
        }

        if (psettings.skipInactiveSystems) {
            settings.skipInactiveSystems = psettings.skipInactiveSystems
        }

        return settings
    }
}

export {
    PrefillV2Parser
}