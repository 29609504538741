<script setup lang="ts">
import { defineComponent } from 'vue';
</script>

<template>
    <div id="page-contributors" class="page-element">
	    <div id="trashsim-title-wrapper">
            <h1 id="trashsim-title" class="title">
                <a href="/nl">TrashSim</a>
            </h1>
            <div id="trashsim-menu" class="clearfix">
                <ul>
                    <li><a target="_blank" id="help-feedback" class="help-feedback" href="https://board.en.ogame.gameforge.com/index.php?thread/771533-trashsim-ogame-combat-simulator/">↳ Geef feedback</a></li>
                    <li><a target="_blank" id="help-translate" class="help-translate" href="https://board.en.ogame.gameforge.com/index.php?thread/771533-trashsim-ogame-combat-simulator/">¶ Word vertaler</a></li>
                    <li><a id="menu-ogotcha" href="https://ogotcha.universeview.be/" target="blank">⚖ OGotcha</a></li>
                </ul>
            </div>
        </div>            	 
        <div id="contributors">
            <h2>Bijdragers</h2>
            <div class="contributors-block">
                <h3>Special thanks &amp; medals to</h3>
                <ul>
                    <li>Parsec</li>
                    <li>NoMoreAngel</li>
                </ul>
            </div>
            <div class="contributors-block">
                <h3>Awesome testers</h3>
                <ul>
                    <li>Mostaris</li>
                    <li>dboxer</li>
                    <li>BlackBEERd</li>
                    <li>BrokenJade</li>
                    <li>DrKill20ften</li>
                </ul>
            </div>
            <div class="contributors-block">
                <h3>Vertalers</h3>
                <ul>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/en.png">Warsaalk, n00b</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/nl.png">Warsaalk, n00b</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/de.png">NoMoreAngel, swizzor</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/es.png">Kang, Minion</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/hr.png">APW</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/it.png">BlackStorm</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/hu.png">Boostocska, Peti</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/pt.png">Elcap</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/sv.png">Henke</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/tr.png">HyperX, GameMaster, Snowbros</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/ko.png">Antis</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/pt-BR.png">RamonXD</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/da.png">ErikFyr</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/pl.png">joniewim</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/cs.png">EscaperCZ</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/fr.png">gogo</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/ro.png">Virian</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/ru.png">VL[AD], Tzachitx, Cycluk</li>
                    <li><img src="//trashsim.oplanet.eu/assets/img/flags/el.png">Johanna, Mumra</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default defineComponent({})
</script>