import type { Entity } from "../entityInfo";
import type { Settings } from "../settings";
import type { Techs } from "../types";

function GetCargoCapacity(entity: Entity, technologies: Techs, settings: Settings): number {
    let base = entity.cargoCapacity || 0
    let result = base

    if (settings.cargoHyperspaceFactor > 0) {
        result += base * (settings.cargoHyperspaceFactor / 100) * (technologies.hyperspacetech || 0)
    }

    return result
}

export {
    GetCargoCapacity
}