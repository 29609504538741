import type { BaseStatsBooster } from "@/api/types";
import type { Entity } from "@/lib/entityInfo";
import type { EntityStat } from "@/lib/newsimulator/party";
import type { Settings } from "@/lib/settings";
import type { Techs } from "@/lib/types";

type techKey = keyof Techs

type techModifiers = modifier[];

type modifier = {
    base: keyof Entity
    technology: techKey,
    multiplier: number
    target: keyof EntityStat & keyof Entity
}

type lfModifiers = lfModifier[];

type lfModifier = {
    base: keyof Entity
    booster: keyof BaseStatsBooster
    target: keyof EntityStat & keyof Entity
}

const modifiers: techModifiers = [
    { base: "weapon", technology: "weapon", multiplier: 0.1, target: "weapon" },
    { base: "shield", technology: "shield", multiplier: 0.1, target: "shield" },
    { base: "armour", technology: "armour", multiplier: 0.1, target: "armour" },
]

// speed and cargo aren't relevant for the simulator, so we don't need to pass them
// it might be clear to calculate this once, or create a seperate function for the speed and cargo modifiers
const lfCombatModifiers: lfModifiers = [
    // { base: "speed", booster: "speed", target: "speed" },
    { base: "armour", booster: "armor", target: "armour" },
    { base: "shield", booster: "shield", target: "shield" },
    { base: "weapon", booster: "weapon", target: "weapon" },
    // { base: "cargoCapacity", booster: "cargo", target: "cargoCapacity" }
]

function CreateEntitySimpleStats(entityId: string, entity: Entity, technologies: Techs, baseStatsBooster: BaseStatsBooster | null, settings: Settings): EntityStat {
    let result: EntityStat = {
        weapon: entity.weapon,
        shield: entity.shield,
        armour: entity.armour,
        rapidFire: entity.rapidfireAgainst
    }

    for (const mod of modifiers) {
        result[mod.target] += applyModifier(
            entity[mod.base],
            mod.multiplier,
            technologies[mod.technology] || 0
        )
    }

    if (baseStatsBooster == null || !settings.lifeformsEnabled) {
        return result
    }

    for (const lfmod of lfCombatModifiers) {
        result[lfmod.target] += applyModifier(
            entity[lfmod.base],
            baseStatsBooster[lfmod.booster] || 0,
            1
        )
    }

    return result
}

function applyModifier(base: any, multiplier: number, level: number): number {
    return Math.floor(base * multiplier * level)
}

export {
    CreateEntitySimpleStats
}