import type { ServerData } from "@/api/types"

export enum OGameVersions {
    V9 = "9.x.x",
    V10 = "10.x.x"
}

export function GetVersion(version: string): OGameVersions {
    if (version.startsWith('10.')) {
        return OGameVersions.V10
    }
    return OGameVersions.V9
}

class Settings {
    simulations: number
    plunder: number
    fleetWarSpeed: number
    fleetHoldSpeed: number
    fleetToDebris: number
    defenceToDebris: number
    defenceRepair: number
    numberOfGalaxies: number
    deuteriumSaveFactor: number
    cargoHyperspaceFactor: number
    donutGalaxy: boolean
    donutSystem: boolean
    version: OGameVersions

    // v7 settings
    combatDebrisFieldLimit: number
    minerBonusIncreasedCargoCapacityForTrandingShips: number

    // v9 settings
    lifeformsEnabled: boolean
    deuteriumInDebris: boolean
    skipInactiveSystems: boolean

    constructor() {
        this.simulations = 50;
        this.plunder = 50;
        this.fleetWarSpeed = 1;
        this.fleetHoldSpeed = 1;
        this.fleetToDebris = 30;
        this.defenceToDebris = 0;
        this.defenceRepair = 70;
        this.numberOfGalaxies = 9;
        this.deuteriumSaveFactor = 1;
        this.cargoHyperspaceFactor = 5;
        this.donutGalaxy = true;
        this.donutSystem = true;
        this.version = OGameVersions.V9;

        // v7 settings
        this.combatDebrisFieldLimit = 25;
        this.minerBonusIncreasedCargoCapacityForTrandingShips = 25;

        // v9 settings
        this.lifeformsEnabled = false;
        this.deuteriumInDebris = false;
        this.skipInactiveSystems = false;
    }
}

function SettingsFromServerData(serverData: ServerData, lootPercentage: number): Settings {
    let settings = new Settings();

    settings.plunder = lootPercentage
    settings.fleetWarSpeed = serverData.speedFleetWar * 1
    settings.fleetHoldSpeed = serverData.speedFleetHolding * 1
    settings.fleetToDebris = serverData.debrisFactor * 100
    settings.numberOfGalaxies = serverData.galaxies * 1
    settings.deuteriumSaveFactor = serverData.globalDeuteriumSaveFactor * 1
    settings.cargoHyperspaceFactor = serverData.cargoHyperspaceTechMultiplier * 1
    settings.lifeformsEnabled = serverData.lifeformSettings !== null;
    settings.deuteriumInDebris = serverData.deuteriumInDebris == 1
    settings.version = GetVersion(serverData.version)

    return settings
}

export {
    SettingsFromServerData,
    Settings
}